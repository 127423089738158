const PopoutCalendarDay = ({ date, newClass, setNewDate, disabled }) => {
    const onClicked = () => {
        if (disabled) return;
        setNewDate(date);
    }

    return (
        <li className={"pop-calendar-day " + newClass}>
            <div className="pop-calendar-day-content" onClick={onClicked}>
                <p>
                    { date.getDate() }
                </p>
            </div>
        </li>
    );
}

export default PopoutCalendarDay;