import { useState, useEffect } from 'react';
import { API, graphqlOperation } from 'aws-amplify';
import * as queries from '../graphql/queries';
import * as subscriptions from '../graphql/subscriptions';
import { createPlanner } from '../graphql/mutations';

import toast from 'react-hot-toast';

import DashboardCard from '../components/dashboard/DashboardCard';
import DashboardWidget from '../components/dashboard/DashboardWidget';
import DashboardTasksWidget from '../components/dashboard/DashboardTasksWidget';
import DashboardEventsWidget from '../components/dashboard/DashboardEventsWidget';
import TaskSVG from '../components/util/TaskSVG';
import EventSVG from '../components/util/EventSVG';
import { CategoryColors } from '../util/CategoryColors';

function Dashboard() {
    const [planners, setPlanners] = useState([]);

    const [tasks, setTasks] = useState([]);
    const [events, setEvents] = useState([]);

    useEffect(() => {
        const plannerCreateSub = API.graphql(graphqlOperation(subscriptions.onCreatePlanner)).subscribe({
            next: ({ value }) => {
                setPlanners(planners => {
                    return [...planners, value.data.onCreatePlanner];
                });
            }
        });

        const plannerUpdateSub = API.graphql(graphqlOperation(subscriptions.onUpdatePlanner)).subscribe({
            next : ({ value }) => {
                setPlanners(planners => {
                    const toUpdateIndex = planners.findIndex(item => item.id === value.data.onUpdatePlanner.id);
                    return [...planners.slice(0, toUpdateIndex), value.data.onUpdatePlanner, ...planners.slice(toUpdateIndex + 1)]
                });
            }
        });

        const plannerDeleteSub = API.graphql(graphqlOperation(subscriptions.onDeletePlanner)).subscribe({
            next : ({ value }) => {
                setPlanners(planners => {
                    const toDeleteIndex = planners.findIndex(item => item.id === value.data.onDeletePlanner.id);
                    return [...planners.slice(0, toDeleteIndex), ...planners.slice(toDeleteIndex + 1)];
                });
            }
        });

        return () => {
            plannerCreateSub.unsubscribe();
            plannerUpdateSub.unsubscribe();
            plannerDeleteSub.unsubscribe();
        }
    }, []);

    useEffect(() => {
        setPlanners([]);

        const fetchEventsTasks = async () => {
            console.log('Fetching planners...');
            try {
                const plannersResult = await API.graphql({ query: queries.listPlanners });
                setPlanners(plannersResult.data.listPlanners.items);
            }
            catch (err) {
                toast.error('Error connecting to Plainner');
            }
        }

        fetchEventsTasks();
    }, []);

    useEffect(() => {
        const taskCreateSub = API.graphql(graphqlOperation(subscriptions.onCreateTask)).subscribe({
            next: ({ value }) => {
                setTasks(tasks => {
                    if (tasks.length !== 0) {
                        if (tasks[tasks.length - 1].key === 'temp' && tasks[tasks.length - 1].title === value.data.onCreateTask.title) {
                            tasks.pop();
                        }
                    }
                    return [...tasks, value.data.onCreateTask];
                });
            }
        });

        const taskUpdateSub = API.graphql(graphqlOperation(subscriptions.onUpdateTask)).subscribe({
            next : ({ value }) => {
                setTasks(tasks => {
                    const toUpdateIndex = tasks.findIndex(item => item.id === value.data.onUpdateTask.id);
                    return [...tasks.slice(0, toUpdateIndex), value.data.onUpdateTask, ...tasks.slice(toUpdateIndex + 1)]
                });
            }
        });

        const taskDeleteSub = API.graphql(graphqlOperation(subscriptions.onDeleteTask)).subscribe({
            next : ({ value }) => {
                setTasks(tasks => {
                    const toDeleteIndex = tasks.findIndex(item => item.id === value.data.onDeleteTask.id);
                    return [...tasks.slice(0, toDeleteIndex), ...tasks.slice(toDeleteIndex + 1)];
                });
            }
        });

        return () => {
            taskCreateSub.unsubscribe();
            taskUpdateSub.unsubscribe();
            taskDeleteSub.unsubscribe();
            
        }
    }, []);

    useEffect(() => {
        const eventCreateSub = API.graphql(graphqlOperation(subscriptions.onCreateEvent)).subscribe({
            next: ({ value }) => {
                setEvents(events => {
                    if (events.length !== 0) {
                        if (events[events.length - 1].key === 'temp' && events[events.length - 1].title === value.data.onCreateEvent.title) {
                            events.pop();
                        }
                    }
                    return [...events, value.data.onCreateEvent];
                });
            }
        });

        const eventUpdateSub = API.graphql(graphqlOperation(subscriptions.onUpdateEvent)).subscribe({
            next : ({ value }) => {
                setEvents(events => {
                    const toUpdateIndex = events.findIndex(item => item.id === value.data.onUpdateEvent.id);
                    return [...events.slice(0, toUpdateIndex), value.data.onUpdateEvent, ...events.slice(toUpdateIndex + 1)]
                });
            }
        });

        const eventDeleteSub = API.graphql(graphqlOperation(subscriptions.onDeleteEvent)).subscribe({
            next : ({ value }) => {
                setEvents(events => {
                    const toDeleteIndex = events.findIndex(item => item.id === value.data.onDeleteEvent.id);
                    return [...events.slice(0, toDeleteIndex), ...events.slice(toDeleteIndex + 1)];
                });
            }
        });

        return () => {
            eventCreateSub.unsubscribe();
            eventUpdateSub.unsubscribe();
            eventDeleteSub.unsubscribe();
        }
    }, [])

    useEffect(() => {
        setTasks([]);
        setEvents([]);

        const fetchTasksEvents = async () => {
            try {
                const allPlanners = await API.graphql({ query: queries.listPlanners });
                allPlanners.data.listPlanners.items.forEach((planner) => {
                    setTasks((prevTasks) => [
                        ...prevTasks,
                        ...planner.tasks.items
                    ]);
                    setEvents((prevEvents) => [
                        ...prevEvents,
                        ...planner.events.items
                    ]);
                });
            }
            catch (err) {

            }
        }

        fetchTasksEvents();
    }, []);

    const saveNewPlanner = async () => {
        const planner = {
            title: 'New planner',
            color: CategoryColors.GREEN
        }
        try {
            await API.graphql(graphqlOperation(createPlanner, {input: planner}));
        }
        catch (err) {
            console.log(err);
        }
    }

    return (
        <>
            <div className="wrapper">
                <div className="dashboard-container">
                    <div className="dashboard-content">
                        <div>
                            <div className="dashboard-title">
                                <h1>Dashboard</h1>
                                <button onClick={saveNewPlanner} className="btn">Create planner</button>
                            </div>
                        </div>
                        <div className="dashboard-cards-container">
                            {
                                planners.map(planner => <DashboardCard id={planner.id} title={planner.title} color={planner.color} key={planner.id} />)
                            }
                        </div>
                        <div className="insights-container">
                            <div className="dashboard-title">
                                <h1>Insights</h1>
                            </div>
                            <div className="insights-widgets-container">
                                <div className="insights-column">
                                    <DashboardWidget title="Events" icon={<EventSVG />}>
                                        <DashboardEventsWidget planners={planners} events={events} />
                                    </DashboardWidget>
                                </div>
                                <div className="insights-column">
                                    <DashboardWidget title="Tasks" icon={<TaskSVG />}>
                                        <DashboardTasksWidget tasks={tasks} />
                                    </DashboardWidget>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Dashboard;