import SignInPrompt from '../components/auth/SignInPrompt';

function Login() {
    return (
        <div>
            <SignInPrompt />
        </div>
    );
}

export default Login;