const CalendarEventElement = ({ date, event }) => {
    const getClass = () => {
        if (event.date === null) {
            return 'calendar-event event-blank';
        }
        function getDateOnly(date) {
            const year = date.getFullYear();
            const month = date.getMonth();
            const day = date.getDate();
            return new Date(year, month, day);
        }
        const localDate = getDateOnly(new Date(date)).getTime();
        const eventStartDate = getDateOnly(new Date(event.date)).getTime();
        const eventEndDate = getDateOnly(new Date(event.endDate)).getTime();
        
        if (localDate === eventStartDate && localDate === eventEndDate) return 'calendar-event';
        else if (localDate === eventStartDate) return 'calendar-event start-event-day';
        else if (localDate > eventStartDate && localDate < eventEndDate) return 'calendar-event median-day';
        else if (localDate === eventEndDate) return 'calendar-event end-event-day';
    }

    const getTime = () => {
        const eventDate = new Date(event.date);
        if (eventDate.getMinutes() === 0) {
            return eventDate.toLocaleTimeString('en-us', {hour: 'numeric'});
        }
        else {
            return eventDate.toLocaleTimeString('en-us', {hour: 'numeric', minute: '2-digit'});
        }
    }

    return (
        <div className={getClass()} style={{borderColor: event.color, backgroundColor:event.color+"26"}}>
            <div className="calendar-event-title-container">
                <p className="calendar-event-date">{event.isAllDay ? "All day" : getTime()}</p>
                <p>{event.title}</p>
            </div>
            <div className="calendar-event-date-container">
                <p></p>
            </div>
        </div>
    );
}

export default CalendarEventElement;