import React from 'react';
import ReactDOM from 'react-dom/client';
import './style.css';
import App from './App';
import { Amplify, Auth } from 'aws-amplify';
import { BrowserRouter } from "react-router-dom";
import aws_exports from './aws-exports'

Amplify.configure({
  ...aws_exports,
  graphql_headers: async () => {
    try {
      const token = (await Auth.currentSession()).idToken.jwtToken;
      return { Authorization: token }
    }
    catch (e) {
        console.error(e);
        return {};
    }
  }
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
    <App />
  </BrowserRouter>
);