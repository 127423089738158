import { Route, Routes } from 'react-router-dom';
import ProtectedRoute from './ProtectedRoute';
import WithNav from './WithNav';
import WithAppNav from './WithAppNav';

import HomePage from '../pages/Home';
import FeaturesPage from '../pages/Features';
import SupportPage from '../pages/Support';
import AboutPage from '../pages/About';
import RegisterPage from '../pages/Register';
import LoginPage from '../pages/Login';
import ForgotPasswordPage from '../pages/ForgotPassword';
import Profile from '../pages/Profile';
import Dashboard from '../pages/Dashboard';
import PlainnerMain from '../pages/PlainnerMain';
import Security from '../pages/Security';
import Email from '../pages/Email';
import Preferences from '../pages/Preferences';
import Subscriptions from '../pages/Subscriptions';

function Router() {
    return (
        <Routes>
            <Route element={<WithNav />}>
                <Route path='/' element={<HomePage />} />
            </Route>
            <Route element={<WithNav />}>
                <Route path='/features' element={<FeaturesPage />} />
            </Route>
            <Route element={<WithNav />}>
                <Route path='/support' element={<SupportPage />} />
            </Route>
            <Route element={<WithNav />}>
                <Route path='/about' element={<AboutPage />} />
            </Route>
            <Route path='/register' element={<RegisterPage />} />
            <Route path='/login' element={<LoginPage />} />
            <Route path='/forgotpassword' element={<ForgotPasswordPage />} />
            <Route element={<WithAppNav />}>
                <Route path='/account/profile' element={
                        <ProtectedRoute>
                            <Profile />
                        </ProtectedRoute>
                    } 
                />
            </Route>
            <Route element={<WithAppNav />}>
                <Route path='/account/security' element={
                        <ProtectedRoute>
                            <Security />
                        </ProtectedRoute>
                    } 
                />
            </Route>
            <Route element={<WithAppNav />}>
                <Route path='/account/email' element={
                        <ProtectedRoute>
                            <Email />
                        </ProtectedRoute>
                    } 
                />
            </Route>
            <Route element={<WithAppNav />}>
                <Route path='/account/subscriptions' element={
                        <ProtectedRoute>
                            <Subscriptions />
                        </ProtectedRoute>
                    } 
                />
            </Route>
            <Route element={<WithAppNav />}>
                <Route path='/account/preferences' element={
                        <ProtectedRoute>
                            <Preferences />
                        </ProtectedRoute>
                    } 
                />
            </Route>
            <Route element={<WithAppNav />}>
                <Route path='/dashboard' element={
                        <ProtectedRoute>
                            <Dashboard />
                        </ProtectedRoute>
                    } 
                />
            </Route>
            <Route path='/p/:id' element={<PlainnerMain />} />
        </Routes>
    )
}

export default Router;