const OutlinerWidget = ({ children, title, icon }) => {
    return (
        <div className="outliner-widget">
            <div className="outliner-widget-title">
                { icon }
                <h2>{ title}</h2>
            </div>
            { children }
        </div>
    );
}

export default OutlinerWidget;