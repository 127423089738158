import DashboardTaskElement from './DashboardTaskElement';

const DashboardTasksWidget = ({ tasks }) => {
	const date = new Date();

	const filteredTasks = tasks
        .filter(task => new Date(task.date).toLocaleDateString('en-US') === date.toLocaleDateString('en-US'))
        .map(task => (
            <DashboardTaskElement
            	plannerId={task.plannerTasksId}
                id={task.id}
                date={task.date}
                status={task.status} 
                color={task.color}
                title={task.title} 
                description={task.description} 
                dueDate={task.dueDate}
                key={task.id}
            />
        ));

	return (
		<div>
			{
				filteredTasks.length > 0 ? (
					filteredTasks
				) : (
					<div className="no-elements-banner-container">
						<h4 className="no-elements-header">No tasks for today</h4>
						<p className="no-elements-text">Kick back and relax.</p>
					</div>
				)
			}
		</div>
	)
}

export default DashboardTasksWidget;