import { useState, useEffect } from 'react';

const ManagerSidebar = ({ content, changeContent }) => {
    const [shownContent, setShownContent] = useState(content);

    useEffect(() => {
        setShownContent(content);
    }, [content]);

    const changePage = (e) => {
        changeContent(e.target.id);
    }

    return (
        <nav className="planner-management-sidebar">
            <div className="planner-management-sidebar-content">
                <div className="planner-management-sidebar-links">
                    <p id="overview" onClick={changePage} className={shownContent === "overview" ? "planner-management-active" : ""}>Overview</p>
                    <p id="categories" onClick={changePage} className={shownContent === "categories" ? "planner-management-active" : ""}>Categories</p>
                    <p id="themes" onClick={changePage} className={shownContent === "themes" ? "planner-management-active" : ""}>Themes</p>
                    <p id="notifications" onClick={changePage} className={shownContent === "notifications" ? "planner-management-active" : ""}>Notifications</p>
                    <p id="subscriptions" onClick={changePage} className={shownContent === "subscriptions" ? "planner-management-active" : ""}>Subscriptions</p>
                    <div className="links-divider"></div>
                    <p id="members" onClick={changePage} className={shownContent === "members" ? "planner-management-active" : ""}>Members</p>
                    <p id="permissions" onClick={changePage} className={shownContent === "permissions" ? "planner-management-active" : ""}>Permissions</p>
                    <div className="links-divider"></div>
                    <p id="preferences" onClick={changePage} className={shownContent === "preferences" ? "planner-management-active" : ""}>Delete planner</p>
                </div>
            </div>
        </nav>
    )
}

export default ManagerSidebar;