import { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import ProfileImage from './ProfileImage';
import Modal from './util/Modal';
import Popout from './util/Popout';
import PlannerSettings from './manager/PlannerSettings'
import CogSVG from './util/CogSVG';

import { API, graphqlOperation } from 'aws-amplify';
import * as queries from '../graphql/queries';
import * as subscriptions from '../graphql/subscriptions';
import * as mutations from '../graphql/mutations';

import AutosizeInput from './util/AutosizeInput';

const AppPlannerNavbar = ({ plannerId }) => {
    const [user, setUser] = useState({
        email: '',
        name: '',
        password: ''
    });
    const [profileShown, setProfileShown] = useState(false);
    const navigate = useNavigate();

    const [managerPopoutShown, setManagerPopoutShown] = useState(false);
    const [plannerManagerShown, setPlannerManagerShown] = useState(false);
    const [shareModalShown, setShareModalShown] = useState(false);
    const [planner, setPlanner] = useState(null);
    const [sharedEmail, setSharedEmail] = useState('');

    useEffect(() => {
        setPlanner(null);
        const getUserInfo = async () => {
            try {
                const currentUser = await Auth.currentUserInfo();
                setUser({
                    email: currentUser.attributes.email,
                    name: currentUser.attributes.name
                });
            }
            catch (err) {
                console.log(err);
            }
        }
        getUserInfo();
    }, []);

    useEffect(() => {
        setPlanner(null);
        const plannerUpdateSub = API.graphql(graphqlOperation(subscriptions.onUpdatePlanner)).subscribe({
            next : ({ value }) => {
                setPlanner(value.data.onUpdatePlanner);
            }
        });

        const getPlanner = async () => {
            try {
                const result = await API.graphql({ query: queries.getPlanner, variables: { id: plannerId } });
                setPlanner(result.data.getPlanner);
            }
            catch (err) {
                console.log(err);
            }
        }

        if (plannerId !== null) getPlanner();

        return () => {
            plannerUpdateSub.unsubscribe();
        }
    }, [plannerId]);

    const onPlannerEdit = (e) => {
        setPlanner({
            ...planner,
            [e.target.name]: e.target.value
        });
    }

    const editPlanner = async () => {
        if (planner.title.trim().length === 0) {
            setPlanner({
                ...planner,
                title: 'placeholder'
            });
            return;
        }
        try {
            const updated = {
                id: plannerId,
                title: planner.title
            }
            await API.graphql({ query: mutations.updatePlanner, variables: { input: updated }});
        }
        catch (err) {
            console.log(err);
        }
    }

    const sharePlanner = async (e) => {
        try {
            await API.graphql(graphqlOperation(mutations.updatePlanner, {
                input: {
                    id: plannerId,
                    shared: [...planner.shared, sharedEmail]
                }
            }));
        }
        catch (err) {
            console.log(err);
        }
    }

    const deletePlanner = async () => {
        try {
            const deleted = {
                id: plannerId
            }
            await API.graphql({ query: mutations.deletePlanner, variables: { input: deleted }});
            navigate('/dashboard')
        }
        catch (err) {
            console.log(err);
        }
    }

    const showManager = () => {
        setPlannerManagerShown(!plannerManagerShown);
        setManagerPopoutShown(false);
    }

    const showManagerPopout = () => {
        setManagerPopoutShown(!managerPopoutShown);
    }

    const showShareModal = () => {
        setPlannerManagerShown(false);
        setManagerPopoutShown(false);
        setShareModalShown(!shareModalShown);
    }

    const setNewSharedEmail = (e) => {
        setSharedEmail(e.target.value)
    }

    const showProfile = () => {
        setProfileShown(!profileShown);
    }

    const signOut = async () => {
        try {
            await Auth.signOut();
            navigate("/");
        }
        catch (err) {
            console.log(err);
        }
    }

    return (
        <div className="app-navbar">
            <div className="app-navbar-content">
                <Link to="/" className="site-logo">
                    <h2>Plainner</h2>
                </Link>
                <div className="nav-list" style={{flex:1}}>
                    <ul>
                        <Link to="/dashboard">
                            <p>Dashboard</p>
                        </Link>
                    </ul>
                </div>
                {
                    planner !== null && plannerId !== null && (
                         <div className="planner-manager">
                            <div className="planner-name-container">
                                <AutosizeInput inputClassName="name-item-input planner-name-input" name="title" value={planner.title} onChange={onPlannerEdit} onBlur={editPlanner} />
                            </div>
                            <div className="planner-management-container">
                                
                            </div>
                        </div>
                    )
                }
                <div className="inline-management-container">
                    <button className="btn btn-small" onClick={showShareModal}>Share</button>
                    <div className="profile-container">
                        <button id="profile-button" className="profile-button" onClick={showProfile}>
                            <ProfileImage name={user.name} />
                        </button>
                        <Popout buttonId="profile-button" popoutClass="profile-dropdown" shown={profileShown} close={() => setProfileShown(false)}>
                            <div>
                                <div className="profile-preview">
                                    <ProfileImage name={user.name} />
                                    <div className="profile-preview-info">
                                        <p>{user.name}</p>
                                        <p>{user.email}</p>
                                    </div>
                                </div>
                                <Link to="/account/profile">
                                    <p className="profile-dropdown-button">Profile</p>
                                </Link>
                                <Link to="/account/security">
                                    <p className="profile-dropdown-button">Security</p>
                                </Link>
                                <Link to="/account/email">
                                <   p className="profile-dropdown-button">Email</p>
                                </Link>
                                <Link to="/account/subscriptions">
                                    <p className="profile-dropdown-button">Subscriptions</p>
                                </Link>
                                <Link to="/account/preferences">
                                    <p className="profile-dropdown-button">Preferences</p>
                                </Link>
                                <p className="profile-dropdown-heading"></p>
                                <p className="profile-dropdown-button" onClick={signOut}>Sign out</p>
                            </div>
                        </Popout>
                    </div>
                    <div className="management-container">
                        <button id="kebab-button" className="inline-management-button" onClick={showManagerPopout}>
                            <CogSVG />
                        </button>
                        <Popout buttonId="kebab-button" popoutClass="profile-dropdown" shown={managerPopoutShown} close={() => setManagerPopoutShown(false)}>
                            <div>
                                <p className="profile-dropdown-button" onClick={showManager}>Planner settings</p>
                                <p className="profile-dropdown-heading"></p>
                                <p className="profile-dropdown-button" onClick={showShareModal}>Share planner</p>
                                <p className="profile-dropdown-button" onClick={showManager}>Notifications</p>
                            </div>
                        </Popout>
                    </div>
                    
                    {
                        plannerManagerShown && (
                            <PlannerSettings plannerId={plannerId} planner={planner} shown={plannerManagerShown} close={() => showManager(false)}/>
                        )
                    }
                    {
                        shareModalShown && (
                            <Modal shown={shareModalShown} close={showShareModal} className="share-modal">
                                <div>
                                    <h3 className="share-modal-title">Share planner</h3>
                                    <div className="share-input-container">
                                        <input className="share-input" onChange={setNewSharedEmail} value={sharedEmail} placeholder="Email address..."></input>
                                        <button onClick={sharePlanner} className="btn btn-wide">Share</button>
                                    </div>
                                </div>
                            </Modal>
                        )
                    }
                </div>
            </div>
        </div>
    );
}

export default AppPlannerNavbar;