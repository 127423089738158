import RegisterPrompt from '../components/auth/RegisterPrompt';
import { useNavigate } from 'react-router-dom';

function Register() {
    const navigation = useNavigate();

    return (
        <div>
            <RegisterPrompt navigate={navigation}/>
        </div>
    );
}

export default Register;