import CalendarEventElement from './CalendarEventElement';

const CalendarDay = ({ date, events, newClass, setNewDate }) => {
    const onClicked = () => {
        setNewDate(date);
    }

    return (
        <li className={ "calendar-day " + newClass }>
            <div className="calendar-day-content" onClick={onClicked}>
                <h2>
                    { date.getDate() }
                </h2>
                { 
                    events.map(event =>
                        <CalendarEventElement date={date} event={event} key={event.id} />
                    ) 
                }
            </div>
        </li>
    );
}

export default CalendarDay;