import { useState, useEffect } from 'react';
import { Auth } from 'aws-amplify';
import ProfileSidebar from '../components/profile/ProfileSidebar';

import { toast, Toaster } from 'react-hot-toast';

const Email = () => {
    const [user, setUser] = useState({
        email: '',
        name: ''
    });

    useEffect(() => {
        const getUserInfo = async () => {
            try {
                const currentUser = await Auth.currentUserInfo();
                setUser({
                    email: currentUser.attributes.email,
                    name: currentUser.attributes.name
                });
            }
            catch (err) {
                console.log(err);
            }
        }
        getUserInfo();
    }, []);

    const setInfo = (e) => {
        setUser({
            ...user,
            [e.target.name]: e.target.value
        });
    }

    const updateUser = async () => {
        try {
            const userObj = await Auth.currentAuthenticatedUser();
            await Auth.updateUserAttributes(userObj, { email: user.email });
            toast.success('Changes have been saved.');
        }
        catch (err) {
            console.log(err);
            toast.error('Error saving changes, please try again.');
        }
    }

    return (
        <div className="wrapper">
            <div className="account-management-container">
                <ProfileSidebar user={user} />
                <div className="account-management-wrapper">
                    <div className="account-management-content">
                        <h1 className="account-management-title">Email</h1>
                        <p>Manage the email that you sign in with.</p>
                        <br></br>
                        <div className="column-content">
                            <h3 className="account-management-subtitle">Email</h3>
                            <input className="account-management-input" value={user.email} name="email" onChange={setInfo}></input>
                            <button className="btn" onClick={updateUser}>Save changes</button>
                        </div>
                    </div>
                </div>
            </div>
            <Toaster position="bottom-left"/>
        </div>
    )
}

export default Email;