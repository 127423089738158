import { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import AuthToaster from './AuthToaster';
import { Auth } from 'aws-amplify';
import { AuthErrors } from '../../util/AuthErrors';

export default function ForgotPasswordPrompt() {
    const [state, setState] = useState({
        email: '',
        newPassword: '',
        authenticationCode: '',
        step: 0,
        message: '',
        awaiting: false
    });
    const navigate = useNavigate();

    function handleSubmit(event) {
        event.preventDefault();
        sendResetRequest();
    }

    function handleFinalSubmit(event) {
        event.preventDefault();
        finalizeResetRequest();
    }

    const onChange = (e) => {
        setState({
            ...state,
            [e.target.name]: e.target.value
        });
    }

    const sendResetRequest = async () => {
        setState({
            ...state,
            awaiting: true
        });
        try {
            await Auth.forgotPassword(state.email);
            setState({
                ...state,
                step: 1,
                message: null,
                awaiting: false
            });
        }
        catch (err) {
            console.log(err);
            switch (err.message) {
                case 'Username/client id combination not found.':
                    setState({
                        ...state,
                        message: AuthErrors.RESET_NO_ACCOUNT,
                        awaiting: false
                    });
                    break;
                case 'Invalid verification code provided, please try again.':
                    setState({
                        ...state,
                        message: AuthErrors.RESET_INCORRECT_CODE,
                        awaiting: false
                    });
                    break;
                case 'Attempt limit exceeded, please try again after some time.':
                    setState({
                        ...state,
                        message: AuthErrors.RESET_TOO_MANY_ATTEMPTS,
                        awaiting: false
                    });
                    break;
                default:
                    setState({
                        ...state,
                        message: AuthErrors.GENERIC,
                        awaiting: false
                    });
            }
            
        }
    }

    const finalizeResetRequest = async () => {
        setState({
            ...state,
            awaiting: true
        });
        try {
            await Auth.forgotPasswordSubmit(state.email, state.authenticationCode, state.newPassword);
            navigate('/dashboard', { replace: true });

        } catch (err) {
            switch (err.message) {
                case 'Confirmation code cannot be empty':
                    setState({
                        ...state,
                        message: AuthErrors.RESET_NO_CODE,
                        awaiting: false
                    });
                    break;
                case 'Password cannot be empty':
                    setState({
                        ...state,
                        message: AuthErrors.PASSWORD_EMPTY,
                        awaiting: false
                    });
                    break;
                default:
                    setState({
                        ...state,
                        message: AuthErrors.GENERIC,
                        awaiting: false
                    });
                    break;
            }
        }
    }

    return (
        <div className="auth-container">
            {
                state.step === 0 && (
                    <>
                        <div className="auth-content">
                            <h1>Plainner</h1>
                            <p>Password reset</p>
                            <AuthToaster message={state.message}/>
                            <form onSubmit={handleSubmit}>
                                <div>
                                    <label htmlFor="email-input" >Email address</label>
                                    <input id="email-input" onChange={onChange} placeholder="Email address" name="email" autoComplete="off"></input>
                                </div>
                                <button className={state.awaiting ? "btn btn-await" : "btn"} onClick={handleSubmit} type="submit">
                                    {
                                        !state.awaiting ? "Email the code" : "Emailing..."
                                    }
                                </button>
                            </form>
                        </div>
                        <div className="auth-alternate-container">
                            <div className="auth-alternate-content">
                                <Link className="auth-link" to="/login">Back to sign in</Link>
                            </div>
                        </div>
                    </>
                )
            }
            {
                state.step === 1 && (
                    <>
                        <div className="auth-content">
                            <h1>Plainner</h1>
                            <p>Password reset</p>
                            <AuthToaster message={state.message}/>
                            <form onSubmit={handleSubmit}>
                                <div>
                                    <label htmlFor="authcode-input" >Verification code</label>
                                    <input id="authcode-input" onChange={onChange} placeholder="Verification code" name="authenticationCode" autoComplete="off"></input>
                                </div>
                                <div>
                                    <label htmlFor="password-input" >New password</label>
                                    <input id="password-input" onChange={onChange} placeholder="New password" name="newPassword" type="password" autoComplete="off"></input>
                                </div>
                                <button className={state.awaiting ? "btn btn-await" : "btn"} onClick={handleFinalSubmit} type="submit">
                                    {
                                        !state.awaiting ? "Verify" : "Verifying..."
                                    }
                                </button>
                            </form>
                        </div>
                        <div className="auth-alternate-container">
                            <div className="auth-alternate-content">
                                <Link className="auth-link" to="/login">Back to sign in</Link>
                            </div>
                        </div>
                    </>
                )
            }
            
        </div>
    );
}