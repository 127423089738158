import { useState, useEffect } from 'react';
import ReactDOM from "react-dom";

import { API } from 'aws-amplify';
import * as mutations from '../../graphql/mutations';

import ManagerSidebar from './ManagerSidebar';
import ColorGridItem from '../outliner/ColorGridItem'
import { CategoryColors } from '../../util/CategoryColors';

const PlannerSettings = ({ plannerId, planner, shown, close }) => {
    const [editedPlanner, setEditedPlanner] = useState({
        title: '',
        color: ''
    });
    const [shownContent, setShownContent] = useState('overview');

    useEffect(() => {
        setEditedPlanner(planner);
    }, [planner]);

    useEffect(() => {
        savePlanner();
    }, [editedPlanner.color])

    const savePlanner = async () => {
       if (editedPlanner.title.trim().length === 0) {
            setEditedPlanner({
                ...editedPlanner,
                title: planner.title
            });
            return;
        }
        try {
            const updated = {
                id: plannerId,
                title: editedPlanner.title,
                color: editedPlanner.color
            }
            await API.graphql({ query: mutations.updatePlanner, variables: { input: updated }});
        }
        catch (err) {
            console.log(err);
        }
    }

    const editPlanner = (e) => {
        setEditedPlanner({
            ...editedPlanner,
            [e.target.name]: e.target.value
        });
    }

    const showContent = (newContent) => {
        setShownContent(newContent);
    }

	return shown ? ReactDOM.createPortal(
        <div className="modal-backdrop" onClick={close}>
            <div className="manager-modal" onClick={e => { e.stopPropagation(); }}>
                <button className="modal-close" onClick={close}></button>
                <div className="planner-management-container">
                    <ManagerSidebar content={shownContent} changeContent={showContent} />
                    {
                        shownContent === "overview" && (
                            <div className="planner-management-content">
                                <h1 className="planner-management-title">Planner Overview</h1>
                                <p>Quickly access important properties.</p>
                                <br></br>
                                <div className="column-content">
                                    <div className="row-content">
                                        <div>
                                            <h3 className="planner-management-subtitle">Planner name</h3>
                                            <input className="planner-management-input" name="title" value={editedPlanner.title} onChange={editPlanner} onBlur={savePlanner}></input>
                                        </div>
                                        <div>
                                            <h3 className="planner-management-subtitle">Dashboard card color</h3>
                                            <div className="task-color-grid-container">
                                                <ColorGridItem color={CategoryColors.RED} clickEvent={editPlanner} />
                                                <ColorGridItem color={CategoryColors.ORANGE} clickEvent={editPlanner} />
                                                <ColorGridItem color={CategoryColors.YELLOW} clickEvent={editPlanner} />
                                                <ColorGridItem color={CategoryColors.GREEN} clickEvent={editPlanner} />
                                                <ColorGridItem color={CategoryColors.BLUE} clickEvent={editPlanner} />
                                                <ColorGridItem color={CategoryColors.PURPLE} clickEvent={editPlanner} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                    }
                    {
                        shownContent === "categories" && (
                            <div className="planner-management-content">
                                <h1 className="planner-management-title">Planner Categories</h1>
                                <p>Change the categories that you use throughout your planner.</p>
                                <br></br>
                                <div className="column-content">
                                    <h3 className="planner-management-subtitle">Work in progress!</h3>
                                </div>
                            </div>
                        )
                    }
                    {
                        shownContent === "notifications" && (
                            <div className="planner-management-content">
                                <h1 className="planner-management-title">Planner Notifications</h1>
                                <p>Manage the notifications that you receive.</p>
                                <br></br>
                                <div className="column-content">
                                    <h3 className="planner-management-subtitle">Work in progress!</h3>
                                </div>
                            </div>
                        )
                    }
                    {
                        shownContent === "subscriptions" && (
                            <div className="planner-management-content">
                                <h1 className="planner-management-title">Subscriptions</h1>
                                <p>Manage your active subscriptions.</p>
                                <br></br>
                                <div className="column-content">
                                    <h3 className="planner-management-subtitle">Work in progress!</h3>
                                </div>
                            </div>
                        )
                    }
                    {
                        shownContent === "members" && (
                            <div className="planner-management-content">
                                <h1 className="planner-management-title">Members</h1>
                                <p>Manage your planner's members.</p>
                                <br></br>
                                <div className="column-content">
                                    <h3 className="planner-management-subtitle">Work in progress!</h3>
                                </div>
                            </div>
                        )
                    }
                    {
                        shownContent === "permissions" && (
                            <div className="planner-management-content">
                                <h1 className="planner-management-title">Permissions</h1>
                                <p>Manage permissions.</p>
                                <br></br>
                                <div className="column-content">
                                    <h3 className="planner-management-subtitle">Work in progress!</h3>
                                </div>
                            </div>
                        )
                    }
                    {
                        shownContent === "preferences" && (
                            <div className="planner-management-content">
                                <h1 className="planner-management-title">Preferences</h1>
                                <p>Manage your planner.</p>
                                <br></br>
                                <div className="column-content">
                                    <h3 className="planner-management-subtitle">Planner name</h3>
                                    <input className="planner-management-input" name="name"></input>
                                    <button className="btn">Save changes</button>
                                </div>
                            </div>
                        )
                    }
                </div>
            </div>
        </div>
		,
        document.getElementById("planner-portal-container")
    ) : null;
}

export default PlannerSettings;