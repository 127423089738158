const Outliner = ({ shown, children, toggleOutliner }) => {
    const outlinerClass = shown ? "outliner" : "outliner-collapsed";

    return (
        <div className={ outlinerClass }>
            <div className="outliner-container">
                {
                    children
                }
            </div>
            
            <div className="outliner-right" onClick={!shown ? toggleOutliner : null}>
                <button className="collapse-button" onClick={toggleOutliner}>
                    <svg style={shown ? {rotate: "180deg", transition: "rotate 0.1s ease-in-out"} : {rotate: "0deg", transition: "rotate 0.1s ease-in-out"}}width="24" height="24" viewBox="0 0 24 24" role="presentation"><path d="M10.294 9.698a.988.988 0 010-1.407 1.01 1.01 0 011.419 0l2.965 2.94a1.09 1.09 0 010 1.548l-2.955 2.93a1.01 1.01 0 01-1.42 0 .988.988 0 010-1.407l2.318-2.297-2.327-2.307z" fill="#d6d6d6" fillRule="evenodd"></path></svg>
                </button>
            </div>
        </div>
    );
}

export default Outliner;