import { useState } from 'react';
import { Auth } from 'aws-amplify';
import { Link, useNavigate } from 'react-router-dom';
import AuthToaster from './AuthToaster';
import { AuthErrors } from '../../util/AuthErrors';

function RegisterPrompt() {
    const [state, setState] = useState({ 
        email: '', 
        password: '',
        name: '',
        authenticationCode: '', 
        step: 0,
        message: null, 
        awaiting: false 
    });
    const navigate = useNavigate();

    const onChange = (e) => {
        setState({
            ...state, 
            [e.target.name]: e.target.value 
        });
    }

    function handleSubmit(event) {
        event.preventDefault();
        signUp();
    }
    
    function handleConfirmSubmit(event) {
        event.preventDefault();
        confirmSignUp();
    }

    const signUp = async () => {
        const email = state.email;
        const password = state.password;
        const name = state.name;
        const phone_number = null;
        const picture = null;
        setState({
            ...state,
            awaiting: true 
        });
        try {
            await Auth.signUp({ username: email, password: password, attributes: { email, name, phone_number, picture }, autoSignIn: { enabled: true } });
            setState({
                ...state,
                step: 1, 
                message: null, 
                awaiting: false 
            });
        } catch (err) {
            switch (err.message) {
                case 'Username cannot be empty':
                    setState({
                        ...state,
                        message: AuthErrors.EMAIL_EMPTY, 
                        awaiting: false 
                    });
                    break;
                case 'Password cannot be empty':
                    setState({
                        ...state,
                        message: AuthErrors.PASSWORD_EMPTY, 
                        awaiting: false 
                    });
                    break;
                case 'Invalid email address format.':
                    setState({
                        ...state,
                        message: AuthErrors.INVALID_EMAIL_FORMAT, 
                        awaiting: false 
                    });
                    break;
                case 'An account with the given email already exists.':
                    setState({
                        ...state,
                        message: AuthErrors.DUPLICATE_EMAIL, 
                        awaiting: false 
                    });
                    break;
                case 'Password did not conform with policy: Password not long enough':
                    setState({
                        ...state,
                        message: AuthErrors.PASSWORD_NOT_LONG, 
                        awaiting: false 
                    });
                    break;
                default:
                    setState({
                        ...state,
                        message: AuthErrors.GENERIC, 
                        awaiting: false 
                    });
                    break;
            }
            
        }
    }

    const confirmSignUp = async () => {
        const email = state.email;
        const authenticationCode = state.authenticationCode;
        setState({
            ...state,
            awaiting: true 
        });
        try {
            await Auth.confirmSignUp(email, authenticationCode);
            setState({
                ...state,
                awaiting: false 
            });
            navigate('/dashboard', { replace: true });
        } catch (err) { 
            console.log(err);
            setState({
                ...state,
                message: 'Verification code is incorrect.  Please try again.', 
                awaiting: false 
            });
        }
    }

    const resendSignUp = async () => {
        try {
            await Auth.resendSignUp(state.email);
            setState({
                ...state,
                message: 'We resent a verification code.'
            });
        } catch (err) {
            console.log(err);
        }
    }

    return (
        <div className="auth-container">
            {
                state.step === 0 && (
                    <>
                        <div className="auth-content">
                            <h1>Plainner</h1>
                            <p>Register</p>
                            <AuthToaster message={state.message}/>
                            <form>
                                <div>
                                    <label htmlFor="email-input">Email</label>
                                    <input id="email-input" onChange={onChange} name="email" placeholder="Email address" autoComplete="off"></input>
                                </div>
                                <div>
                                    <label htmlFor="name-input">Name</label>
                                    <input id="name-input" onChange={onChange} name="name" placeholder="Name" autoComplete="off"></input>
                                </div>
                                <div>
                                    <label htmlFor="password-input">Password</label>
                                    <input id="password-input" onChange={onChange} name="password" placeholder="Password" type="password" autoComplete="off"></input>
                                </div>
                                <button className={state.awaiting ? "btn btn-await" : "btn"} onClick={handleSubmit} type="submit">
                                    {
                                        !state.awaiting ? "Register" : "Registering..."
                                    }
                                </button>
                            </form>
                            
                        </div>
                        <div className="auth-alternate-container">
                            <div className="auth-alternate-content">
                                <Link className="auth-link" to="/login">Or sign in</Link>
                            </div>
                        </div>
                    </>
                )
            }
            {
                state.step === 1 && (
                    <div className="auth-content">
                        <h1>Plainner</h1>
                        <p>Register</p>
                        <AuthToaster message={state.message} />
                        <form>
                            <div>
                                <label htmlFor="authcode-input">Verification code</label>
                                <input id="authcode-input" onChange={onChange} name="authenticationCode" autoComplete="off"></input>
                            </div>
                            <button className={state.awaiting ? "btn btn-await" : "btn"} onClick={handleConfirmSubmit}>
                                {
                                    !state.awaiting ? "Complete sign up" : "Verifying..."
                                }
                            </button>
                            <button className="auth-link" onClick={resendSignUp}>Resend verification email</button>
                        </form>
                        
                    </div>
                )
            }
        </div>
    );
}

export default RegisterPrompt;