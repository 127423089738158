import { useState, useEffect, useRef } from 'react';
import { API } from 'aws-amplify';
import * as mutations from '../../graphql/mutations';
import { toast } from 'react-hot-toast';
import BlurryDatePicker from '../util/BlurryDatePicker';

import Modal from '../util/Modal';
import Popout from '../util/Popout';
import PopoutCalendar from '../calendar/PopoutCalendar';
import ColorGridItem from './ColorGridItem';
import { CategoryColors } from '../../util/CategoryColors';

const EventElement = ({ id, plannerId, date, endDate, isAllDay, color, title, description }) => {
    const [editedEvent, setEditedEvent] = useState({
        id: id,
        date: date,
        endDate: endDate,
        isAllDay: isAllDay,
        color: color,
        title: title,
        description: description,
    })
    const [modalOpen, showModal] = useState(false);
    const [colorPopoutOpen, showColorPopout] = useState(false);
    const [datePopoutOpen, showDatePopout] = useState(false);
    const [editorOpen, showEditor] = useState(false);

    const [inputSelection, setInputSelection] = useState(false);

    const propColor = useRef(color);
    const propId = useRef(id);

    // Update editedTask when props change
    useEffect(() => {
        setEditedEvent(previousState => ({
            date: date,
            endDate: endDate,
            isAllDay: isAllDay,
            color: color,
            title: title,
            description: description,
        }));
        propColor.current = color;
    }, [date, endDate, isAllDay, color, title, description]);

    // Update task immediately when color changes
    useEffect(() => {
        const updateEvent = async () => {
            try {
                const updated = {
                    id: propId.current,
                    plannerEventsId: plannerId,
                    color: editedEvent.color
                }
                await API.graphql({ query: mutations.updateEvent, variables: { input: updated }});
            }
            catch (err) {
                toast.error('Error saving task');
            }
        }
        if (propColor.current !== editedEvent.color) updateEvent();
    }, [editedEvent.color, plannerId]);
    
    // General edit event
    const editEvent = (e) => {
        setEditedEvent({
            ...editedEvent,
            [e.target.name]: e.target.value
        })
    }

    // Edit date when we press a date in PopoutCalendar
    const onDateSelected = (date) => {
        if (date === null) return;

        let newDate = date.toISOString();
        // if no end date then just set the date and return
        if (editedEvent.endDate === null) {
            setEditedEvent({
                ...editedEvent,
                date: newDate
            });
            return;
        }
        // set the date as normal
        if (date < new Date(editedEvent.endDate)) {
            setEditedEvent({
                ...editedEvent,
                date: newDate
            });
            setInputSelection(!inputSelection);
        }
        // flip dates since end date can't be before the start date
        else {
            setEditedEvent({
                ...editedEvent,
                date: editedEvent.endDate,
                endDate: newDate
            });
        }
    }

    const onEndDateSelected = (date) => {
        if (date === null) return;

        let newDate = date.toISOString();
        if (date > new Date(editedEvent.date)) {
            setEditedEvent({
                ...editedEvent,
                endDate: newDate
            });
            setInputSelection(!inputSelection);
        }
        else {
            setEditedEvent({
                ...editedEvent,
                endDate: editedEvent.date,
                date: newDate
            });
        }
    }

    // Edit date when we type it in to the input box
    const onDateEntered = (date) => {
        if (date === null) return;

        let newDate = date.toISOString();
        // if no end date then just set the date and return
        if (editedEvent.endDate === null) {
            setEditedEvent({
                ...editedEvent,
                date: newDate
            });
            return;
        }
        if (date < new Date(editedEvent.endDate)) {
            setEditedEvent({
                ...editedEvent,
                date: newDate
            });
        }
        // This function is special since we don't swap dates but make them equal
        else {
            setEditedEvent({
                ...editedEvent,
                date: newDate,
                endDate: newDate
            });
        }
    }

    // Edit date when we type it in to the input box
    const onEndDateEntered = (date) => {
        if (date === null) return;
        let newDate = date.toISOString();
        if (date > new Date(editedEvent.date)) {
            setEditedEvent({
                ...editedEvent,
                endDate: newDate
            });
        }
        // This function is special since we don't swap dates but make them equal
        else {
            setEditedEvent({
                ...editedEvent,
                endDate: newDate,
                date: newDate
            });
        }
    }
    
    const getStartDate = () => {
        return new Date(editedEvent.date)
    }

    const getEndDate = () => {
        if (editedEvent.endDate !== null) return new Date(editedEvent.endDate);
    }

    // Returns the date for this event as a formatted date (shown in the event list)
    const getFormattedDateText = () => {
        if (!editedEvent.isAllDay) {
            const startDate = new Date(editedEvent.date);
            const endDate = new Date(editedEvent.endDate);
            const current = new Date();
            if (startDate.toDateString() === current.toDateString()) {
                if (endDate.toDateString() === current.toDateString()) {
                    return `${startDate.toLocaleTimeString('en-US', {hour: '2-digit', minute: '2-digit'})} to ${endDate.toLocaleTimeString('en-US', {hour: '2-digit', minute: '2-digit'})}`;
                }
                else {
                    return `${startDate.toLocaleTimeString('en-US', {hour: '2-digit', minute: '2-digit'})} to ${endDate.toLocaleDateString('en-US')}`;
                }
            }
            else {
                if (endDate.toDateString() === current.toDateString()) {
                    return `${startDate.toLocaleDateString('en-US')} to today at ${endDate.toLocaleTimeString('en-US', {hour: '2-digit', minute: '2-digit'})}`;
                }
                else {
                    return `${startDate.toLocaleDateString('en-US')} to ${endDate.toLocaleDateString('en-US')}`;
                }
            }
        }
        else {
            const startDate = new Date(editedEvent.date);
            const endDate = new Date(editedEvent.endDate);
            const current = new Date();
            if (startDate.toDateString() === current.toDateString()) {
                if (endDate.toDateString() === current.toDateString()) {
                    return `All day today`;
                }
                else {
                    return `Today to ${endDate.toLocaleDateString('en-US')}`;
                }
            }
            else {
                if (endDate.toDateString() === current.toDateString()) {
                    return `${startDate.toLocaleDateString('en-US')} to today`;
                }
                else {
                    return `${startDate.toLocaleDateString('en-US')} to ${endDate.toLocaleDateString('en-US')}`;
                }
            }
        }
    }

    // When isAllDay checkbox is changed set the endDate appropriately
    const onIsAllDayChanged = (e) => {
        setInputSelection(false);
        setEditedEvent({
            ...editedEvent,
            isAllDay: e.target.checked
        });
    }

    const onDateInputClicked = (e) => {
        if (e.target.name === 'date') setInputSelection(false);
        else setInputSelection(true);
    }

    const getDateButtonText = () => {
        if (!editedEvent.isAllDay) {
            const startDate = new Date(editedEvent.date);
            const endDate = new Date(editedEvent.endDate);
            const current = new Date();
            if (startDate.toDateString() === current.toDateString()) {
                if (endDate.toDateString() === current.toDateString()) {
                    return `Today from ${startDate.toLocaleTimeString('en-US', {hour: '2-digit', minute: '2-digit'})} to ${endDate.toLocaleTimeString('en-US', {hour: '2-digit', minute: '2-digit'})}`;
                }
                else {
                    return `Today at ${startDate.toLocaleTimeString('en-US', {hour: '2-digit', minute: '2-digit'})} to ${endDate.toLocaleDateString('en-US')}`;
                }
            }
            else {
                if (endDate.toDateString() === current.toDateString()) {
                    return `${startDate.toLocaleDateString('en-US')} to today at ${endDate.toLocaleTimeString('en-US', {hour: '2-digit', minute: '2-digit'})}`;
                }
                else {
                    return `${startDate.toLocaleDateString('en-US')} to ${endDate.toLocaleDateString('en-US')}`;
                }
            }
        }
        else {
            const startDate = new Date(editedEvent.date);
            const endDate = new Date(editedEvent.endDate);
            const current = new Date();
            if (startDate.toDateString() === current.toDateString()) {
                if (endDate.toDateString() === current.toDateString()) {
                    return `All day today`;
                }
                else {
                    return `Today to ${endDate.toLocaleDateString('en-US')}`;
                }
            }
            else {
                if (endDate.toDateString() === current.toDateString()) {
                    return `${startDate.toLocaleDateString('en-US')} to today`;
                }
                else {
                    return `${startDate.toLocaleDateString('en-US')} to ${endDate.toLocaleDateString('en-US')}`;
                }
            }
        }
    }

    const closeModal = () => {
        showModal(false);
        showDatePopout(false);
        showColorPopout(false);
    }

    // AWS: save event
    const saveEvent = async () => {
        if (editedEvent.title.trim().length === 0) {
            toast.error('Title cannot be empty!');
            return;
        }
        if (isNaN(new Date(editedEvent.date))) {
            toast.error('Event date is invalid!');
            return;
        }
        try {
            const updated = {
                id: id,
                plannerEventsId: plannerId,
                date: editedEvent.date,
                endDate: editedEvent.endDate,
                isAllDay: editedEvent.isAllDay,
                color: editedEvent.color,
                title: editedEvent.title,
                description: editedEvent.description,
            }
            await API.graphql({ query: mutations.updateEvent, variables: { input: updated } });
        }
        catch (err) {
            toast.error('Error saving event');
            console.log(err);
        }
    }

    // AWS: delete event
    const deleteEvent = async () => {
        try {
            const deleted = {
                id: id
            }
            await API.graphql({ query: mutations.deleteEvent, variables: { input: deleted }});
        }
        catch (err) {
            console.log(err);
            toast.error('Error deleting event');
        }
    }

    return (
        <li className="event">
            <span className="event-color" style={{ backgroundColor: editedEvent.color }}></span>
            <div className="event-title-button" onClick={() => showModal(true)}>
                <p>{ editedEvent.title }</p>
                <h5>{ getFormattedDateText() }</h5>
            </div>
            <Modal shown={modalOpen} close={closeModal}>
                <div className="task-modal-title-container">
                    <textarea className="task-modal-title" name="title" onChange={editEvent} onBlur={saveEvent} value={editedEvent.title} placeholder="Add a title..."></textarea>
                </div>
                <div className="task-modal-options-container">
                    <div className="task-modal-option">
                        <button id="date-button" className="btn btn-small btn-task-modal" onClick={() => showDatePopout(!datePopoutOpen)}>
                            <svg height="24 " width="24" className="btn-svg" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <g id="SVGRepo_bgCarrier"></g>
                                <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g>
                                <g id="SVGRepo_iconCarrier"> 
                                    <path d="M12 9.5V13.5L14.5 15M12 5C7.30558 5 3.5 8.80558 3.5 13.5C3.5 18.1944 7.30558 22 12 22C16.6944 22 20.5 18.1944 20.5 13.5C20.5 8.80558 16.6944 5 12 5ZM12 5V2M10 2H14M20.329 5.59204L18.829 			4.09204L19.579 4.84204M3.67102 5.59204L5.17102 4.09204L4.42102 4.84204" stroke="#fff" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path> 
                                </g>
                            </svg>
                            { getDateButtonText() }
                        </button>
                        <Popout buttonId="date-button" title="Event date" shown={datePopoutOpen} close={() => showDatePopout(false)}>
                            <div className="due-date-info-container">
                                <PopoutCalendar 
                                    date={inputSelection ? editedEvent.endDate : editedEvent.date} 
                                    dueDate={inputSelection ? editedEvent.endDate : editedEvent.date} 
                                    setNewDate={inputSelection ? onEndDateSelected : onDateSelected} 
                                    secondaryDate={inputSelection ? editedEvent.date : editedEvent.endDate}
                                />
                                <p style={{margin:0}}>Event date</p>
                                <div className="event-date-container">
                                    <BlurryDatePicker 
                                        className={inputSelection ? "date-input" : "date-input event-date-selected"} 
                                        name="date"  
                                        selected={getStartDate()}
                                        onFocus={onDateInputClicked}
                                        onBlur={onDateEntered}
                                        open={false} 
                                        placeholderText="M/D/YYYY"
                                    />
                                    <BlurryDatePicker 
                                        className={inputSelection ? "time-input" : editedEvent.isAllDay ? "time-input" : "time-input event-date-selected"} 
                                        name="date"  
                                        selected={!editedEvent.isAllDay ? getStartDate() : null} 
                                        onFocus={onDateInputClicked}
                                        onBlur={onDateEntered}
                                        open={false} 
                                        showTimeSelect 
                                        showTimeSelectOnly 
                                        dateFormat="h:mm aa" 
                                        placeholderText="H:MM A"
                                        disabled={editedEvent.isAllDay}
                                    />
                                    <input className="task-checkbox" type="checkbox" name="isAllDay" checked={editedEvent.isAllDay} onChange={onIsAllDayChanged}></input>
                                    <p>All day</p>
                                </div>
                                <div className="event-date-container">
                                    <BlurryDatePicker 
                                        className={inputSelection ? "date-input event-date-selected" : "date-input"} 
                                        name="endDate" 
                                        selected={getEndDate()}
                                        onFocus={onDateInputClicked}
                                        onBlur={onEndDateEntered}
                                        open={false} 
                                        placeholderText="M/D/YYYY"
                                    />
                                    <BlurryDatePicker 
                                        className={inputSelection ? !editedEvent.isAllDay ? "time-input event-date-selected" : "time-input" : "time-input"} 
                                        name="endDate" 
                                        selected={!editedEvent.isAllDay ? getEndDate() : null}
                                        onFocus={onDateInputClicked}
                                        onBlur={onEndDateEntered}
                                        open={false} 
                                        showTimeSelect showTimeSelectOnly 
                                        dateFormat="h:mm aa" 
                                        placeholderText="H:MM A" 
                                        disabled={editedEvent.isAllDay}
                                    />
                                </div>
                                <button className="btn btn-small" onClick={() => { saveEvent(); showDatePopout(false) }}>Save</button>
                            </div>
                        </Popout>
                    </div>
                    <div className="task-modal-option">
                        <button id="color-button" className="btn btn-small btn-task-modal" onClick={() => showColorPopout(!colorPopoutOpen)} style={{backgroundColor: editedEvent.color}}>
                            <svg height="24" width="24" className="btn-svg" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <g id="SVGRepo_bgCarrier" strokeWidth="0"></g><g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g>
                                <g id="SVGRepo_iconCarrier"> 
                                    <path d="M8.4 3H4.6C4.03995 3 3.75992 3 3.54601 3.10899C3.35785 3.20487 3.20487 3.35785 3.10899 3.54601C3 3.75992 3 4.03995 3 4.6V8.4C3 8.96005 3 9.24008 3.10899 9.45399C3.20487 9.64215 3.35785 			9.79513 3.54601 9.89101C3.75992 10 4.03995 10 4.6 10H8.4C8.96005 10 9.24008 10 9.45399 9.89101C9.64215 9.79513 9.79513 9.64215 9.89101 9.45399C10 9.24008 10 8.96005 10 8.4V4.6C10 4.03995 10 3.75992 		9.89101 3.54601C9.79513 3.35785 9.64215 3.20487 9.45399 3.10899C9.24008 3 8.96005 3 8.4 3Z" stroke="#fff" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path> 
                                    <path d="M19.4 3H15.6C15.0399 3 14.7599 3 14.546 3.10899C14.3578 3.20487 14.2049 3.35785 14.109 3.54601C14 3.75992 14 4.03995 14 4.6V8.4C14 8.96005 14 9.24008 14.109 9.45399C14.2049 9.64215 14.3578 		9.79513 14.546 9.89101C14.7599 10 15.0399 10 15.6 10H19.4C19.9601 10 20.2401 10 20.454 9.89101C20.6422 9.79513 20.7951 9.64215 20.891 9.45399C21 9.24008 21 8.96005 21 8.4V4.6C21 4.03995 21 3.75992 			20.891 3.54601C20.7951 3.35785 20.6422 3.20487 20.454 3.10899C20.2401 3 19.9601 3 19.4 3Z" stroke="#fff" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path> 
                                    <path d="M19.4 14H15.6C15.0399 14 14.7599 14 14.546 14.109C14.3578 14.2049 14.2049 14.3578 14.109 14.546C14 14.7599 14 15.0399 14 15.6V19.4C14 19.9601 14 20.2401 14.109 20.454C14.2049 20.6422 14.3578 		20.7951 14.546 20.891C14.7599 21 15.0399 21 15.6 21H19.4C19.9601 21 20.2401 21 20.454 20.891C20.6422 20.7951 20.7951 20.6422 20.891 20.454C21 20.2401 21 19.9601 21 19.4V15.6C21 15.0399 21 14.7599 			20.891 14.546C20.7951 14.3578 20.6422 14.2049 20.454 14.109C20.2401 14 19.9601 14 19.4 14Z" stroke="#fff" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path> 
                                    <path d="M8.4 14H4.6C4.03995 14 3.75992 14 3.54601 14.109C3.35785 14.2049 3.20487 14.3578 3.10899 14.546C3 14.7599 3 15.0399 3 15.6V19.4C3 19.9601 3 20.2401 3.10899 20.454C3.20487 20.6422 3.35785 			20.7951 3.54601 20.891C3.75992 21 4.03995 21 4.6 21H8.4C8.96005 21 9.24008 21 9.45399 20.891C9.64215 20.7951 9.79513 20.6422 9.89101 20.454C10 20.2401 10 19.9601 10 19.4V15.6C10 15.0399 10 14.7599 			9.89101 14.546C9.79513 14.3578 9.64215 14.2049 9.45399 14.109C9.24008 14 8.96005 14 8.4 14Z" stroke="#fff" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path> 
                                </g>
                            </svg>
                            Color
                        </button>
                        <Popout title="Color" buttonId="color-button" shown={colorPopoutOpen} close={() => showColorPopout(false)}>
                            <div className="task-color-grid-container">
                                <ColorGridItem color={CategoryColors.RED} clickEvent={editEvent} />
                                <ColorGridItem color={CategoryColors.ORANGE} clickEvent={editEvent} />
                                <ColorGridItem color={CategoryColors.YELLOW} clickEvent={editEvent} />
                                <ColorGridItem color={CategoryColors.GREEN} clickEvent={editEvent} />
                                <ColorGridItem color={CategoryColors.BLUE} clickEvent={editEvent} />
                                <ColorGridItem color={CategoryColors.PURPLE} clickEvent={editEvent} />
                            </div>
                        </Popout>
                    </div>
                </div>
                <p className="description-header">Description</p>
                <textarea className="task-modal-description" name="description" onChange={editEvent} value={editedEvent.description} placeholder="Add a description..." onFocus={() => showEditor(true)}></textarea>
                {
                    editorOpen && (
                        <div>
                            <button className="btn btn-small" onClick={() => {
                                showEditor(false)
                                saveEvent()
                            }}>Save</button>
                            <button className="btn btn-small btn-grey" onClick={() => showEditor(false)}>Cancel</button>
                        </div>
                    )
                }
                <div className="modal-controls-container">
                    <button className="btn btn-small btn-delete" onClick={deleteEvent}>Delete event</button>
                </div>
            </Modal>
        </li>
    );
}

export default EventElement;