import { useState, useEffect } from 'react';
import { Auth } from 'aws-amplify';
import ProfileSidebar from '../components/profile/ProfileSidebar';

const Subscriptions = () => {
    const [user, setUser] = useState({
        email: '',
        name: ''
    });

    useEffect(() => {
        const getUserInfo = async () => {
            try {
                const currentUser = await Auth.currentUserInfo();
                setUser({
                    email: currentUser.attributes.email,
                    name: currentUser.attributes.name
                });
            }
            catch (err) {
                console.log(err);
            }
        }
        getUserInfo();
    }, []);

    return (
        <div className="wrapper">
            <div className="account-management-container">
                <ProfileSidebar user={user} />
                <div className="account-management-wrapper">
                    <div className="account-management-content">
                        <h1 className="account-management-title">Subscriptions</h1>
                        <p>Manage your subscriptions.</p>
                        <br></br>
                        <div className="column-content">
                            <p>Placeholder</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Subscriptions;