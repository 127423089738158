import { useState, useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import ProtectedComponent from '../util/ProtectedComponent';
import ProfileButton from './ProfileButton';
import ProfileImage from './ProfileImage';
import LoginHiddenComponent from '../util/LoginHiddenComponent';
import CustomLink from './util/CustomLink';
import Popout from './util/Popout';

const Navbar = () => {
    const [user, setUser] = useState({
        email: '',
        name: '',
        password: ''
    });
    const [profileShown, setProfileShown] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        const getUserInfo = async () => {
            try {
                const currentUser = await Auth.currentUserInfo();
                setUser({
                    email: currentUser.attributes.email,
                    name: currentUser.attributes.name
                });
            }
            catch (err) {
                console.log(err);
            }
        }
        getUserInfo();
    }, []);

    const showProfile = () => {
        setProfileShown(!profileShown);
    }

    const signOut = async () => {
        try {
            await Auth.signOut();
            navigate("/");
        }
        catch (err) {
            console.log(err);
        }
    }

    return (
        <div className="navbar">
            <div className="navbar-content">
                <Link to="/" className="site-logo">
                    <h2>Plainner<sup>IN DEV</sup></h2>
                </Link>
                <div className="nav-list">
                    <ul>
                        <CustomLink to="/features">Features</CustomLink>
                        <CustomLink to="/support">Support</CustomLink>
                        <CustomLink to="/about">About</CustomLink>
                    </ul>
                </div>
                <div className="profile-container">
                    <LoginHiddenComponent>
                        <button className="btn btn-alt">
                            <Link to="/login">Sign in</Link>
                        </button>
                        <button className="btn">
                            <Link to="/register">Register</Link>
                        </button>
                    </LoginHiddenComponent>
                    <div className="profile-container">
                        <button id="profile-button" className="profile-button" onClick={showProfile}>
                            <ProfileImage name={user.name} />
                        </button>
                        <Popout buttonId="profile-button" popoutClass="profile-dropdown" shown={profileShown} close={() => setProfileShown(false)}>
                            <div>
                                <div className="profile-preview">
                                    <ProfileImage name={user.name} />
                                    <div className="profile-preview-info">
                                        <p>{user.name}</p>
                                        <p>{user.email}</p>
                                    </div>
                                </div>
                                <Link to="/account/profile">
                                    <p className="profile-dropdown-button">Profile</p>
                                </Link>
                                <Link to="/account/security">
                                    <p className="profile-dropdown-button">Security</p>
                                </Link>
                                <Link to="/account/email">
                                <   p className="profile-dropdown-button">Email</p>
                                </Link>
                                <Link to="/account/subscriptions">
                                    <p className="profile-dropdown-button">Subscriptions</p>
                                </Link>
                                <Link to="/account/preferences">
                                    <p className="profile-dropdown-button">Preferences</p>
                                </Link>
                                <p className="profile-dropdown-heading"></p>
                                <p className="profile-dropdown-button" onClick={signOut}>Sign out</p>
                            </div>
                        </Popout>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Navbar;