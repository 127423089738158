import { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import ProfileImage from '../ProfileImage';

const ProfileSidebar = () => {
    const [user, setUser] = useState({
        email: '',
        name: ''
    });
    const [url, setUrl] = useState(null);
    const location = useLocation();

    useEffect(() => {
        setUrl(location.pathname);
    }, [location]);

    useEffect(() => {
        const getUserInfo = async () => {
            try {
                const currentUser = await Auth.currentUserInfo();
                setUser({
                    email: currentUser.attributes.email,
                    name: currentUser.attributes.name
                });
            }
            catch (err) {
                console.log(err);
            }
        }
        getUserInfo();
    }, []);

    return (
        <nav className="account-management-sidebar">
            <div className="account-management-sidebar-content">
                <div className="profile-preview" style={{maxWidth:"200px", marginBottom:"10px"}}>
                    <ProfileImage name={user.name} />
                    <div className="profile-preview-info">
                        <p>{user.name}</p>
                    </div>
                </div>
                <div className="account-management-sidebar-links">
                    <Link to="/account/profile">
                        <p className={url === "/account/profile" ? "account-management-active" : ""}>Profile</p>
                    </Link>
                    <Link to="/account/security">
                        <p className={url === "/account/security" ? "account-management-active" : ""}>Security</p>
                    </Link>
                    <Link to="/account/email">
                        <p className={url === "/account/email" ? "account-management-active" : ""}>Email</p>
                    </Link>
                    <Link to="/account/subscriptions">
                        <p className={url === "/account/subscriptions" ? "account-management-active" : ""}>Subscriptions</p>
                    </Link>
                    <Link to="/account/preferences">
                        <p className={url === "/account/preferences" ? "account-management-active" : ""}>Preferences</p>
                    </Link>
                </div>
            </div>
        </nav>
    )
}

export default ProfileSidebar;