/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getPlanner = /* GraphQL */ `
  query GetPlanner($id: ID!) {
    getPlanner(id: $id) {
      id
      title
      color
      tasks {
        items {
          id
          planner {
            id
            title
            color
            tasks {
              nextToken
              __typename
            }
            events {
              nextToken
              __typename
            }
            owner
            shared
            createdAt
            updatedAt
            __typename
          }
          date
          status
          color
          title
          description
          dueDate
          createdAt
          updatedAt
          plannerTasksId
          __typename
        }
        nextToken
        __typename
      }
      events {
        items {
          id
          planner {
            id
            title
            color
            tasks {
              nextToken
              __typename
            }
            events {
              nextToken
              __typename
            }
            owner
            shared
            createdAt
            updatedAt
            __typename
          }
          date
          endDate
          isAllDay
          color
          title
          description
          createdAt
          updatedAt
          plannerEventsId
          __typename
        }
        nextToken
        __typename
      }
      owner
      shared
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listPlanners = /* GraphQL */ `
  query ListPlanners(
    $filter: ModelPlannerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPlanners(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        title
        color
        tasks {
          items {
            id
            planner {
              id
              title
              color
              owner
              shared
              createdAt
              updatedAt
              __typename
            }
            date
            status
            color
            title
            description
            dueDate
            createdAt
            updatedAt
            plannerTasksId
            __typename
          }
          nextToken
          __typename
        }
        events {
          items {
            id
            planner {
              id
              title
              color
              owner
              shared
              createdAt
              updatedAt
              __typename
            }
            date
            endDate
            isAllDay
            color
            title
            description
            createdAt
            updatedAt
            plannerEventsId
            __typename
          }
          nextToken
          __typename
        }
        owner
        shared
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
