import { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { API } from 'aws-amplify';
import * as mutations from '../../graphql/mutations';

const DashboardTaskElement = ({ plannerId, id, date, status, color, title, description, dueDate }) => {
	const [editedStatus, setEditedStatus] = useState(status)
	const navigate = useNavigate();
	const propStatus = useRef(status);

	useEffect(() => {
		setEditedStatus(status);
		propStatus.current = status;
	}, [status]);

	// Update task immediately after status change
    useEffect(() => {
        const updateTask = async () => {
            try {
                const updated = {
                    id: id,
                    status: editedStatus
                }
                const response = await API.graphql({ query: mutations.updateTask, variables: { input: updated }});
            }
            catch (err) {
                
            }
        }
        if (propStatus.current !== editedStatus) updateTask();
    }, [editedStatus]);

	const onStatusEdited = (e) => {
		setEditedStatus(e.target.checked);
	}

	const openPlanner = () => {
		navigate(`/p/${plannerId}`);
	}

	return (
		<li className="task">
            <span className="task-color" style={{backgroundColor: color}}></span>
            <input className="task-checkbox" type="checkbox" name="status" onChange={onStatusEdited} checked={!!editedStatus}></input>
            <div className="task-title-container">
                <button className="task-title-button" onClick={openPlanner}>
                    <span className="task-title">
                        { title }
                    </span>
                </button>
            </div>
        </li>
	);
}

export default DashboardTaskElement;