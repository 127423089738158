import { Navigate } from 'react-router-dom';
import { Auth, Hub } from 'aws-amplify'
import React from 'react';

class ProtectedRoute extends React.Component {
    constructor () {
        super();
        this.state = { isLoggedIn: undefined };
    }

    listenToAutoSignInEvent() {
        Hub.listen('auth', ({ payload }) => {
            const { event } = payload;
            if (event === 'autoSignIn') {
                this.setState({ isLoggedIn: true });
            }
            else if (event === 'autoSignIn_failure') {
                this.setState({ isLoggedIn: false });
            }
        });
    }

    componentDidMount() {
        this.auth();
    }

    auth = async () => {
        try {
            await Auth.currentAuthenticatedUser();
            this.setState({ isLoggedIn: true });
        }
        catch (err) {
            console.log(err);
            this.setState({ isLoggedIn: false });
        }
    }

    render() {
        return (
            <>
                {
                    this.state.isLoggedIn === undefined && (
                        <></>
                    )
                }
                {
                    this.state.isLoggedIn === true && (
                        <>
                            {this.props.children}
                        </>
                    )
                }
                {
                    this.state.isLoggedIn === false && (
                        <>
                            <Navigate to='/login' replace={true}/>
                        </>
                    )
                }
            </>
        )
    }
}

export default ProtectedRoute;