/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createPlanner = /* GraphQL */ `
  mutation CreatePlanner(
    $input: CreatePlannerInput!
    $condition: ModelPlannerConditionInput
  ) {
    createPlanner(input: $input, condition: $condition) {
      id
      title
      color
      tasks {
        items {
          id
          planner {
            id
            title
            color
            tasks {
              nextToken
              __typename
            }
            events {
              nextToken
              __typename
            }
            owner
            shared
            createdAt
            updatedAt
            __typename
          }
          date
          status
          color
          title
          description
          dueDate
          createdAt
          updatedAt
          plannerTasksId
          __typename
        }
        nextToken
        __typename
      }
      events {
        items {
          id
          planner {
            id
            title
            color
            tasks {
              nextToken
              __typename
            }
            events {
              nextToken
              __typename
            }
            owner
            shared
            createdAt
            updatedAt
            __typename
          }
          date
          endDate
          isAllDay
          color
          title
          description
          createdAt
          updatedAt
          plannerEventsId
          __typename
        }
        nextToken
        __typename
      }
      owner
      shared
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updatePlanner = /* GraphQL */ `
  mutation UpdatePlanner(
    $input: UpdatePlannerInput!
    $condition: ModelPlannerConditionInput
  ) {
    updatePlanner(input: $input, condition: $condition) {
      id
      title
      color
      tasks {
        items {
          id
          planner {
            id
            title
            color
            tasks {
              nextToken
              __typename
            }
            events {
              nextToken
              __typename
            }
            owner
            shared
            createdAt
            updatedAt
            __typename
          }
          date
          status
          color
          title
          description
          dueDate
          createdAt
          updatedAt
          plannerTasksId
          __typename
        }
        nextToken
        __typename
      }
      events {
        items {
          id
          planner {
            id
            title
            color
            tasks {
              nextToken
              __typename
            }
            events {
              nextToken
              __typename
            }
            owner
            shared
            createdAt
            updatedAt
            __typename
          }
          date
          endDate
          isAllDay
          color
          title
          description
          createdAt
          updatedAt
          plannerEventsId
          __typename
        }
        nextToken
        __typename
      }
      owner
      shared
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deletePlanner = /* GraphQL */ `
  mutation DeletePlanner(
    $input: DeletePlannerInput!
    $condition: ModelPlannerConditionInput
  ) {
    deletePlanner(input: $input, condition: $condition) {
      id
      title
      color
      tasks {
        items {
          id
          planner {
            id
            title
            color
            tasks {
              nextToken
              __typename
            }
            events {
              nextToken
              __typename
            }
            owner
            shared
            createdAt
            updatedAt
            __typename
          }
          date
          status
          color
          title
          description
          dueDate
          createdAt
          updatedAt
          plannerTasksId
          __typename
        }
        nextToken
        __typename
      }
      events {
        items {
          id
          planner {
            id
            title
            color
            tasks {
              nextToken
              __typename
            }
            events {
              nextToken
              __typename
            }
            owner
            shared
            createdAt
            updatedAt
            __typename
          }
          date
          endDate
          isAllDay
          color
          title
          description
          createdAt
          updatedAt
          plannerEventsId
          __typename
        }
        nextToken
        __typename
      }
      owner
      shared
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createTask = /* GraphQL */ `
  mutation CreateTask(
    $input: CreateTaskInput!
    $condition: ModelTaskConditionInput
  ) {
    createTask(input: $input, condition: $condition) {
      id
      planner {
        id
        title
        color
        tasks {
          items {
            id
            planner {
              id
              title
              color
              owner
              shared
              createdAt
              updatedAt
              __typename
            }
            date
            status
            color
            title
            description
            dueDate
            createdAt
            updatedAt
            plannerTasksId
            __typename
          }
          nextToken
          __typename
        }
        events {
          items {
            id
            planner {
              id
              title
              color
              owner
              shared
              createdAt
              updatedAt
              __typename
            }
            date
            endDate
            isAllDay
            color
            title
            description
            createdAt
            updatedAt
            plannerEventsId
            __typename
          }
          nextToken
          __typename
        }
        owner
        shared
        createdAt
        updatedAt
        __typename
      }
      date
      status
      color
      title
      description
      dueDate
      createdAt
      updatedAt
      plannerTasksId
      __typename
    }
  }
`;
export const updateTask = /* GraphQL */ `
  mutation UpdateTask(
    $input: UpdateTaskInput!
    $condition: ModelTaskConditionInput
  ) {
    updateTask(input: $input, condition: $condition) {
      id
      planner {
        id
        title
        color
        tasks {
          items {
            id
            planner {
              id
              title
              color
              owner
              shared
              createdAt
              updatedAt
              __typename
            }
            date
            status
            color
            title
            description
            dueDate
            createdAt
            updatedAt
            plannerTasksId
            __typename
          }
          nextToken
          __typename
        }
        events {
          items {
            id
            planner {
              id
              title
              color
              owner
              shared
              createdAt
              updatedAt
              __typename
            }
            date
            endDate
            isAllDay
            color
            title
            description
            createdAt
            updatedAt
            plannerEventsId
            __typename
          }
          nextToken
          __typename
        }
        owner
        shared
        createdAt
        updatedAt
        __typename
      }
      date
      status
      color
      title
      description
      dueDate
      createdAt
      updatedAt
      plannerTasksId
      __typename
    }
  }
`;
export const deleteTask = /* GraphQL */ `
  mutation DeleteTask(
    $input: DeleteTaskInput!
    $condition: ModelTaskConditionInput
  ) {
    deleteTask(input: $input, condition: $condition) {
      id
      planner {
        id
        title
        color
        tasks {
          items {
            id
            planner {
              id
              title
              color
              owner
              shared
              createdAt
              updatedAt
              __typename
            }
            date
            status
            color
            title
            description
            dueDate
            createdAt
            updatedAt
            plannerTasksId
            __typename
          }
          nextToken
          __typename
        }
        events {
          items {
            id
            planner {
              id
              title
              color
              owner
              shared
              createdAt
              updatedAt
              __typename
            }
            date
            endDate
            isAllDay
            color
            title
            description
            createdAt
            updatedAt
            plannerEventsId
            __typename
          }
          nextToken
          __typename
        }
        owner
        shared
        createdAt
        updatedAt
        __typename
      }
      date
      status
      color
      title
      description
      dueDate
      createdAt
      updatedAt
      plannerTasksId
      __typename
    }
  }
`;
export const createEvent = /* GraphQL */ `
  mutation CreateEvent(
    $input: CreateEventInput!
    $condition: ModelEventConditionInput
  ) {
    createEvent(input: $input, condition: $condition) {
      id
      planner {
        id
        title
        color
        tasks {
          items {
            id
            planner {
              id
              title
              color
              owner
              shared
              createdAt
              updatedAt
              __typename
            }
            date
            status
            color
            title
            description
            dueDate
            createdAt
            updatedAt
            plannerTasksId
            __typename
          }
          nextToken
          __typename
        }
        events {
          items {
            id
            planner {
              id
              title
              color
              owner
              shared
              createdAt
              updatedAt
              __typename
            }
            date
            endDate
            isAllDay
            color
            title
            description
            createdAt
            updatedAt
            plannerEventsId
            __typename
          }
          nextToken
          __typename
        }
        owner
        shared
        createdAt
        updatedAt
        __typename
      }
      date
      endDate
      isAllDay
      color
      title
      description
      createdAt
      updatedAt
      plannerEventsId
      __typename
    }
  }
`;
export const updateEvent = /* GraphQL */ `
  mutation UpdateEvent(
    $input: UpdateEventInput!
    $condition: ModelEventConditionInput
  ) {
    updateEvent(input: $input, condition: $condition) {
      id
      planner {
        id
        title
        color
        tasks {
          items {
            id
            planner {
              id
              title
              color
              owner
              shared
              createdAt
              updatedAt
              __typename
            }
            date
            status
            color
            title
            description
            dueDate
            createdAt
            updatedAt
            plannerTasksId
            __typename
          }
          nextToken
          __typename
        }
        events {
          items {
            id
            planner {
              id
              title
              color
              owner
              shared
              createdAt
              updatedAt
              __typename
            }
            date
            endDate
            isAllDay
            color
            title
            description
            createdAt
            updatedAt
            plannerEventsId
            __typename
          }
          nextToken
          __typename
        }
        owner
        shared
        createdAt
        updatedAt
        __typename
      }
      date
      endDate
      isAllDay
      color
      title
      description
      createdAt
      updatedAt
      plannerEventsId
      __typename
    }
  }
`;
export const deleteEvent = /* GraphQL */ `
  mutation DeleteEvent(
    $input: DeleteEventInput!
    $condition: ModelEventConditionInput
  ) {
    deleteEvent(input: $input, condition: $condition) {
      id
      planner {
        id
        title
        color
        tasks {
          items {
            id
            planner {
              id
              title
              color
              owner
              shared
              createdAt
              updatedAt
              __typename
            }
            date
            status
            color
            title
            description
            dueDate
            createdAt
            updatedAt
            plannerTasksId
            __typename
          }
          nextToken
          __typename
        }
        events {
          items {
            id
            planner {
              id
              title
              color
              owner
              shared
              createdAt
              updatedAt
              __typename
            }
            date
            endDate
            isAllDay
            color
            title
            description
            createdAt
            updatedAt
            plannerEventsId
            __typename
          }
          nextToken
          __typename
        }
        owner
        shared
        createdAt
        updatedAt
        __typename
      }
      date
      endDate
      isAllDay
      color
      title
      description
      createdAt
      updatedAt
      plannerEventsId
      __typename
    }
  }
`;
