/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const onCreatePlanner = /* GraphQL */ `
  subscription OnCreatePlanner(
    $filter: ModelSubscriptionPlannerFilterInput
    $owner: String
  ) {
    onCreatePlanner(filter: $filter, owner: $owner) {
      id
      title
      color
      tasks {
        items {
          id
          planner {
            id
            title
            color
            tasks {
              nextToken
              __typename
            }
            events {
              nextToken
              __typename
            }
            owner
            shared
            createdAt
            updatedAt
            __typename
          }
          date
          status
          color
          title
          description
          dueDate
          createdAt
          updatedAt
          plannerTasksId
          __typename
        }
        nextToken
        __typename
      }
      events {
        items {
          id
          planner {
            id
            title
            color
            tasks {
              nextToken
              __typename
            }
            events {
              nextToken
              __typename
            }
            owner
            shared
            createdAt
            updatedAt
            __typename
          }
          date
          endDate
          isAllDay
          color
          title
          description
          createdAt
          updatedAt
          plannerEventsId
          __typename
        }
        nextToken
        __typename
      }
      owner
      shared
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdatePlanner = /* GraphQL */ `
  subscription OnUpdatePlanner(
    $filter: ModelSubscriptionPlannerFilterInput
    $owner: String
  ) {
    onUpdatePlanner(filter: $filter, owner: $owner) {
      id
      title
      color
      tasks {
        items {
          id
          planner {
            id
            title
            color
            tasks {
              nextToken
              __typename
            }
            events {
              nextToken
              __typename
            }
            owner
            shared
            createdAt
            updatedAt
            __typename
          }
          date
          status
          color
          title
          description
          dueDate
          createdAt
          updatedAt
          plannerTasksId
          __typename
        }
        nextToken
        __typename
      }
      events {
        items {
          id
          planner {
            id
            title
            color
            tasks {
              nextToken
              __typename
            }
            events {
              nextToken
              __typename
            }
            owner
            shared
            createdAt
            updatedAt
            __typename
          }
          date
          endDate
          isAllDay
          color
          title
          description
          createdAt
          updatedAt
          plannerEventsId
          __typename
        }
        nextToken
        __typename
      }
      owner
      shared
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeletePlanner = /* GraphQL */ `
  subscription OnDeletePlanner(
    $filter: ModelSubscriptionPlannerFilterInput
    $owner: String
  ) {
    onDeletePlanner(filter: $filter, owner: $owner) {
      id
      title
      color
      tasks {
        items {
          id
          planner {
            id
            title
            color
            tasks {
              nextToken
              __typename
            }
            events {
              nextToken
              __typename
            }
            owner
            shared
            createdAt
            updatedAt
            __typename
          }
          date
          status
          color
          title
          description
          dueDate
          createdAt
          updatedAt
          plannerTasksId
          __typename
        }
        nextToken
        __typename
      }
      events {
        items {
          id
          planner {
            id
            title
            color
            tasks {
              nextToken
              __typename
            }
            events {
              nextToken
              __typename
            }
            owner
            shared
            createdAt
            updatedAt
            __typename
          }
          date
          endDate
          isAllDay
          color
          title
          description
          createdAt
          updatedAt
          plannerEventsId
          __typename
        }
        nextToken
        __typename
      }
      owner
      shared
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateTask = /* GraphQL */ `
  subscription OnCreateTask($filter: ModelSubscriptionTaskFilterInput) {
    onCreateTask(filter: $filter) {
      id
      planner {
        id
        title
        color
        tasks {
          items {
            id
            planner {
              id
              title
              color
              owner
              shared
              createdAt
              updatedAt
              __typename
            }
            date
            status
            color
            title
            description
            dueDate
            createdAt
            updatedAt
            plannerTasksId
            __typename
          }
          nextToken
          __typename
        }
        events {
          items {
            id
            planner {
              id
              title
              color
              owner
              shared
              createdAt
              updatedAt
              __typename
            }
            date
            endDate
            isAllDay
            color
            title
            description
            createdAt
            updatedAt
            plannerEventsId
            __typename
          }
          nextToken
          __typename
        }
        owner
        shared
        createdAt
        updatedAt
        __typename
      }
      date
      status
      color
      title
      description
      dueDate
      createdAt
      updatedAt
      plannerTasksId
      __typename
    }
  }
`;
export const onUpdateTask = /* GraphQL */ `
  subscription OnUpdateTask($filter: ModelSubscriptionTaskFilterInput) {
    onUpdateTask(filter: $filter) {
      id
      planner {
        id
        title
        color
        tasks {
          items {
            id
            planner {
              id
              title
              color
              owner
              shared
              createdAt
              updatedAt
              __typename
            }
            date
            status
            color
            title
            description
            dueDate
            createdAt
            updatedAt
            plannerTasksId
            __typename
          }
          nextToken
          __typename
        }
        events {
          items {
            id
            planner {
              id
              title
              color
              owner
              shared
              createdAt
              updatedAt
              __typename
            }
            date
            endDate
            isAllDay
            color
            title
            description
            createdAt
            updatedAt
            plannerEventsId
            __typename
          }
          nextToken
          __typename
        }
        owner
        shared
        createdAt
        updatedAt
        __typename
      }
      date
      status
      color
      title
      description
      dueDate
      createdAt
      updatedAt
      plannerTasksId
      __typename
    }
  }
`;
export const onDeleteTask = /* GraphQL */ `
  subscription OnDeleteTask($filter: ModelSubscriptionTaskFilterInput) {
    onDeleteTask(filter: $filter) {
      id
      planner {
        id
        title
        color
        tasks {
          items {
            id
            planner {
              id
              title
              color
              owner
              shared
              createdAt
              updatedAt
              __typename
            }
            date
            status
            color
            title
            description
            dueDate
            createdAt
            updatedAt
            plannerTasksId
            __typename
          }
          nextToken
          __typename
        }
        events {
          items {
            id
            planner {
              id
              title
              color
              owner
              shared
              createdAt
              updatedAt
              __typename
            }
            date
            endDate
            isAllDay
            color
            title
            description
            createdAt
            updatedAt
            plannerEventsId
            __typename
          }
          nextToken
          __typename
        }
        owner
        shared
        createdAt
        updatedAt
        __typename
      }
      date
      status
      color
      title
      description
      dueDate
      createdAt
      updatedAt
      plannerTasksId
      __typename
    }
  }
`;
export const onCreateEvent = /* GraphQL */ `
  subscription OnCreateEvent($filter: ModelSubscriptionEventFilterInput) {
    onCreateEvent(filter: $filter) {
      id
      planner {
        id
        title
        color
        tasks {
          items {
            id
            planner {
              id
              title
              color
              owner
              shared
              createdAt
              updatedAt
              __typename
            }
            date
            status
            color
            title
            description
            dueDate
            createdAt
            updatedAt
            plannerTasksId
            __typename
          }
          nextToken
          __typename
        }
        events {
          items {
            id
            planner {
              id
              title
              color
              owner
              shared
              createdAt
              updatedAt
              __typename
            }
            date
            endDate
            isAllDay
            color
            title
            description
            createdAt
            updatedAt
            plannerEventsId
            __typename
          }
          nextToken
          __typename
        }
        owner
        shared
        createdAt
        updatedAt
        __typename
      }
      date
      endDate
      isAllDay
      color
      title
      description
      createdAt
      updatedAt
      plannerEventsId
      __typename
    }
  }
`;
export const onUpdateEvent = /* GraphQL */ `
  subscription OnUpdateEvent($filter: ModelSubscriptionEventFilterInput) {
    onUpdateEvent(filter: $filter) {
      id
      planner {
        id
        title
        color
        tasks {
          items {
            id
            planner {
              id
              title
              color
              owner
              shared
              createdAt
              updatedAt
              __typename
            }
            date
            status
            color
            title
            description
            dueDate
            createdAt
            updatedAt
            plannerTasksId
            __typename
          }
          nextToken
          __typename
        }
        events {
          items {
            id
            planner {
              id
              title
              color
              owner
              shared
              createdAt
              updatedAt
              __typename
            }
            date
            endDate
            isAllDay
            color
            title
            description
            createdAt
            updatedAt
            plannerEventsId
            __typename
          }
          nextToken
          __typename
        }
        owner
        shared
        createdAt
        updatedAt
        __typename
      }
      date
      endDate
      isAllDay
      color
      title
      description
      createdAt
      updatedAt
      plannerEventsId
      __typename
    }
  }
`;
export const onDeleteEvent = /* GraphQL */ `
  subscription OnDeleteEvent($filter: ModelSubscriptionEventFilterInput) {
    onDeleteEvent(filter: $filter) {
      id
      planner {
        id
        title
        color
        tasks {
          items {
            id
            planner {
              id
              title
              color
              owner
              shared
              createdAt
              updatedAt
              __typename
            }
            date
            status
            color
            title
            description
            dueDate
            createdAt
            updatedAt
            plannerTasksId
            __typename
          }
          nextToken
          __typename
        }
        events {
          items {
            id
            planner {
              id
              title
              color
              owner
              shared
              createdAt
              updatedAt
              __typename
            }
            date
            endDate
            isAllDay
            color
            title
            description
            createdAt
            updatedAt
            plannerEventsId
            __typename
          }
          nextToken
          __typename
        }
        owner
        shared
        createdAt
        updatedAt
        __typename
      }
      date
      endDate
      isAllDay
      color
      title
      description
      createdAt
      updatedAt
      plannerEventsId
      __typename
    }
  }
`;
