import React, { useState } from 'react';
import { Auth } from 'aws-amplify';
import { useNavigate, Link } from 'react-router-dom';
import AuthToaster from './AuthToaster';
import { AuthErrors } from '../../util/AuthErrors';

function SignInPrompt() {
    const [userInfo, setUserInfo] = useState({
        email: '',
        password: '',
    });
    const [message, setMessage] = useState(null);
    const [awaiting, setAwaiting] = useState(false);
    const navigate = useNavigate();

    function handleChange(event) {
        setUserInfo(oldUserInfo => ({
            ...oldUserInfo,
            [event.target.name]: event.target.value
        }));
    }

    function handleSubmit(event) {
        setAwaiting(true);
        signIn();
        event.preventDefault();
    }

    async function signIn() {
        try {
            await Auth.signIn(userInfo.email, userInfo.password);
            navigate('/dashboard', { replace: true });
            setAwaiting(false);
        }
        catch (err) {
            switch (err.message) {
                case 'Username cannot be empty':
                    setMessage(AuthErrors.EMAIL_EMPTY);
                    break;
                case 'Password cannot be empty':
                    setMessage(AuthErrors.PASSWORD_EMPTY);
                    break;
                case 'Incorrect username or password.':
                    setMessage(AuthErrors.LOGIN_INCORRECT);
                    break;
                default:
                    setMessage(AuthErrors.GENERIC);
                    break;
            }
            setAwaiting(false);
        }
    }
    
    return (
        <div className="auth-container">
            <div className="auth-content">
                <h1>Plainner</h1>
                <p>Sign in</p>
                <AuthToaster message={message}/>
                <form onSubmit={handleSubmit}>
                    <div>
                        <label htmlFor="email-input">Email</label>
                        <input id="email-input" onChange={handleChange} value={userInfo.email} name="email" placeholder="Email address" autoComplete="off"></input>
                    </div>
                    <div>
                        <label htmlFor="password-input">Password</label>
                        <input id="password-input" onChange={handleChange} value={userInfo.password} name="password" placeholder="Password" type="password" autoComplete="off"></input>
                        <Link className="auth-link" to="/forgotpassword">Forgot password?</Link>
                    </div>
                    <button className={awaiting ? "btn btn-await" : "btn"} type="submit">
                        {
                            awaiting ? "Signing in..." : "Sign in"
                        }
                    </button>
                </form>
            </div>
            <div className="auth-alternate-container">
                <div className="auth-alternate-content">
                    <Link className="auth-link" to="/register">Or register for an account</Link>
                </div>
            </div>
        </div>
        
    );
}

export default SignInPrompt;