import { useState, useEffect } from 'react';
import CalendarDay from './CalendarDay';

function DesktopCalendar({ date, events, setNewDate }) {
    const [calendar, setCalendar] = useState([]);

    const monthNames = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

    useEffect(() => {
        if (date === null) return;
        // Function to create a day object
        var previousDay = null;
        const createDay = (date, key, newClass) => {
            function getDateOnly(date) {
                const year = date.getFullYear();
                const month = date.getMonth();
                const day = date.getDate();
                return new Date(year, month, day);
            }
            // Filter events by date
            const eventList = events.filter(event => {
                const eventStartDate = getDateOnly(new Date(event.date)).getTime();
                const eventEndDate = getDateOnly(new Date(event.endDate)).getTime();
                const targetDate = getDateOnly(date).getTime();
                
                return targetDate >= eventStartDate && targetDate <= eventEndDate;
            });
            // Insertion sort the list so that we don't fuck up the rendering
            for (var i = 0; i < eventList.length; i++) {
                const eventElement = eventList[i];
                var j = i - 1;
                while (j >= 0 && ((new Date(eventList[j].date).getTime() > new Date(eventElement.date).getTime()) || (new Date(eventList[j].endDate).getTime() - new Date(eventList[j].date).getTime() < new Date(eventElement.endDate).getTime() - new Date(eventElement.date).getTime()))) {
                    eventList[j + 1] = eventList[j];
                    j--;
                }
                eventList[j + 1] = eventElement;
            }
            let calendarDay = {
                date: date,
                events: eventList,
                key: key,
                newClass: newClass
            }
            // Black magic function 
            if (previousDay !== null) {
                // iterate over each previous element in previous day
                for (var a = 0; a < previousDay.events.length; a++) {
                    const prevElement = previousDay.events[a];
                    // iterate over each element in current day
                    for (var b = 0; b < calendarDay.events.length; b++) {
                        const currElement = calendarDay.events[b];
                        // check if elements are equal
                        if (prevElement.id === currElement.id) {
                            const changeInIndex = a - b;
                            if (changeInIndex < 0) {
                                const copy = calendarDay.events[a];
                                calendarDay.events[a] = currElement;
                                calendarDay.events[b] = copy;
                            }
                            else if (changeInIndex > 0) {
                                if (calendarDay.events[a] !== undefined) {
                                    const copy = calendarDay.events[a];
                                    calendarDay.events[a] = currElement;
                                    calendarDay.events[b] = copy;
                                }
                                else {
                                    var index = calendarDay.events.length;
                                    while (index <= a) {
                                        var blankEvent = {
                                            id: index,
                                            title: currElement.title,
                                            date: null,
                                            endDate: null
                                        }
                                        calendarDay.events.splice(index, 0, blankEvent);
                                        index++;
                                    }
                                    const copy = calendarDay.events[a];
                                    calendarDay.events[a] = currElement;
                                    calendarDay.events[b] = copy;
                                }
                            }
                        }
                    }
                }
            }
            if (date.getDay() !== 6) previousDay = calendarDay;
            else previousDay = null;
            return calendarDay;
        }
        // Create a list of every date object for this month
        const createMonth = () => {
            const dateObj = new Date(date);
            const firstDay = new Date(dateObj.getFullYear(), dateObj.getMonth(), 1).getDay();
            const daysCount = new Date(dateObj.getFullYear(), dateObj.getMonth() + 1, 0).getDate();
            const prevMonthCount = new Date(dateObj.getFullYear(), dateObj.getMonth(), 0).getDate();
            let key = 0;
            let daysInCalendar = [];

            for (let i = prevMonthCount - firstDay + 1; i <= prevMonthCount; i++) {
                let newDate;
                if (dateObj.getMonth() === 0) {
                    newDate = new Date(dateObj.getFullYear() - 1, 11, i);
                }
                else {
                    newDate = new Date(dateObj.getFullYear(), dateObj.getMonth() - 1, i);
                }
                let newDay = createDay(newDate, key, 'inactive-month');
                daysInCalendar.push(newDay);
                key++;
            }

            for (let i = 1; i <= daysCount; i++) {
                let newDate = new Date(dateObj.getFullYear(), dateObj.getMonth(), i);
                let newDay = createDay(newDate, key, '');
                // check if primary date is equal to date being added to calendar
                if (newDate.toDateString() === date.toDateString()) newDay = createDay(newDate, key, 'calendar-day-active');
                daysInCalendar.push(newDay);
                key++;
            }
            
            const daysLeft = 42 - daysInCalendar.length;
            for (let i = 1; i <= daysLeft; i++) {
                let newDate;
                if (dateObj.getMonth() === 11) {
                    newDate = new Date(dateObj.getFullYear() + 1, 0, i);
                }
                else {
                    newDate = new Date(dateObj.getFullYear(), dateObj.getMonth() + 1, i);
                }
                let newDay = createDay(newDate, key, 'inactive-month');
                daysInCalendar.push(newDay);
                key++;
            }

            return daysInCalendar;
        }
        
        setCalendar(createMonth()); 
    }, [date, events]);

    const increaseMonth = () => {
        const currentDay = new Date(date);
        currentDay.setMonth(currentDay.getMonth() + 1);
        setNewDate(currentDay);
    }

    const decreaseMonth = () => {
        const currentDay = new Date(date);
        currentDay.setMonth(currentDay.getMonth() - 1);
        setNewDate(currentDay);
    }

    const setDateToday = () => {
        const today = new Date();
        setNewDate(today);
    }

    return (
        <div className="calendar">
            <div className="calendar-header-container">
                <div className="calendar-header">
                    <button className="btn btn-grey btn-wide" onClick={setDateToday}>Today</button>
                    <button className="calendar-control-button" onClick={decreaseMonth}><svg style={{ rotate:'180deg' }}width="40" height="40" viewBox="0 0 24 24" role="presentation"><path d="M10.294 9.698a.988.988 0 010-1.407 1.01 1.01 0 011.419 0l2.965 2.94a1.09 1.09 0 010 1.548l-2.955 2.93a1.01 1.01 0 01-1.42 0 .988.988 0 010-1.407l2.318-2.297-2.327-2.307z" fill="#d6d6d6" fillRule="evenodd"></path></svg></button>
                    <button className="calendar-control-button" onClick={increaseMonth}><svg width="40" height="40" viewBox="0 0 24 24" role="presentation"><path d="M10.294 9.698a.988.988 0 010-1.407 1.01 1.01 0 011.419 0l2.965 2.94a1.09 1.09 0 010 1.548l-2.955 2.93a1.01 1.01 0 01-1.42 0 .988.988 0 010-1.407l2.318-2.297-2.327-2.307z" fill="#d6d6d6" fillRule="evenodd"></path></svg></button>
                    <h1>
                        {
                            monthNames[date.getMonth()] + ' ' + date.getFullYear()
                        }
                    </h1>
                </div>     
            </div>
            <ol className="calendar-day-title-container">
                <CalendarDayTitle day="Sunday" />
                <CalendarDayTitle day="Monday" />
                <CalendarDayTitle day="Tuesday" />
                <CalendarDayTitle day="Wednesday" />
                <CalendarDayTitle day="Thursday" />
                <CalendarDayTitle day="Friday" />
                <CalendarDayTitle day="Saturday" />
            </ol>
            <ol className="planner-calendar">
                {
                    calendar.map((calendarDay) => {
                        return (
                            <CalendarDay 
                                date={calendarDay.date}
                                events={calendarDay.events}
                                newClass={calendarDay.newClass}
                                setNewDate={setNewDate}
                                key={calendarDay.key}
                            />
                        )
                    })
                }
            </ol>
        </div>
        
    );
}

const CalendarDayTitle = ({day}) => {
    return (
        <p className="calendar-day-title">{day}</p>
    )
}

export default DesktopCalendar;