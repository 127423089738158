export const AuthErrors = {
    EMAIL_EMPTY: 'Email cannot be empty.',
    PASSWORD_EMPTY: 'Password cannot be empty.',
    INVALID_EMAIL_FORMAT: 'Email address is invalid.',
    DUPLICATE_EMAIL: 'An account with that email address already exists.',
    PASSWORD_NOT_LONG: 'Password must be at least 8 characters.',
    LOGIN_INCORRECT: 'Incorrect email or password.',
    RESET_NO_ACCOUNT: 'No account was found with this email address.',
    RESET_NO_CODE: 'Verification code cannot be empty.',
    RESET_INCORRECT_CODE: 'Incorrect verification code.',
    RESET_TOO_MANY_ATTEMPTS: 'Attempt limit exceeded.  Please try again later.',
    GENERIC: 'An error occurred.  Please try again later.'
}