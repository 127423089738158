import { useEffect, useRef } from 'react';

function useOutsideAlerter(ref, close, buttonId) {
    useEffect(() => {
        function handleClickOutside(event) {
            if (ref.current && !ref.current.contains(event.target) && event.target.id !== buttonId) {
                close();
            }
        }
        document.addEventListener("mouseup", handleClickOutside);
        return () => {
            document.removeEventListener("mouseup", handleClickOutside);
        };
    }, [ref, close, buttonId]);
}

const Popout = ({ children, popoutClass, buttonId, title, shown, close }) => {
    const popoutRef = useRef(null);
    useOutsideAlerter(popoutRef, close, buttonId);

    return shown ? (
        <div className={popoutClass ? popoutClass : "popout"} onClick={close} ref={popoutRef}>
            <div className={popoutClass ? "" : "popout-content"} onClick={e => { e.stopPropagation(); }}>
                { title ? ( <p className="popout-title">{title}</p> ) : (<></>) }
                { children }
            </div>
        </div>
    ) : null;
}

export default Popout;