import { Link } from 'react-router-dom';
import main from '../img/main.PNG';
import calendar from '../img/calendar.PNG';
import share from '../img/share.PNG';

function Home() {
    return (
        <div className="page-container">
            <div className="hero-container">
                <div className="hero-content">
                    <div className="hero-wrapper">
                        <div className="hero-text-container">
                            <div className="hero-text">
                                <h1 className="hero-title">Plain Planner for Plain People.</h1>
                                <p>Plainner simplifies your workflow by providing you with a simple, easy to use planning app.</p> 
                            </div>
                            <button className="btn btn-large">
                                <Link to="/dashboard">Launch Plainner</Link>
                            </button>
                            
                        </div>
                    </div>
                    
                    <div className="hero-curved-bottom">
                        <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
                            <path className="shape-fill" d="M985.66,92.83C906.67,72,823.78,31,743.84,14.19c-82.26-17.34-168.06-16.33-250.45.39-57.84,11.73-114,31.07-172,41.86A600.21,600.21,0,0,1,0,27.35V120H1200V95.8C1132.19,118.92,1055.71,111.31,985.66,92.83Z"></path>
                        </svg>
                    </div>
                </div>
            </div>
            <div className="features-container">
                <div className="features-content">
                    <div className="features-section">
                        <div className="features-section-title">
                            <h1>A simple approach to your day.</h1>
                            <p>Plainner keeps your events and tasks in reach for each day.  Easily see all of your plans with the clean and customizable calendar.</p>
                        </div>
                        <div className="features-section-image">
                            <div className="outliner-widget">
                                <div className="outliner-widget-title">
                                    <svg className="widget-icon" width="30px" height="30px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M20 11H4M20 11V19C20 20.1046 19.1046 21 18 21H6C4.89543 21 4 20.1046 4 19V7C4 5.89543 4.89543 5 6 5H18C19.1046 5 20 5.89543 20 7V11ZM15 3V7M9 3V7" stroke="#fff" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                    </svg>
                                    <h2>Events</h2>
                                </div>
                                <div>
                                    <ol className="events-list-content">
                                        <li className="event">
                                            <span className="event-color" style={{backgroundColor: "rgb(191, 59, 52)"}}></span>
                                            <div className="event-title-button">
                                                <p>Job interview</p>
                                                <h5>02:00 PM to 02:30 PM</h5>
                                            </div>
                                        </li>
                                        <li className="event">
                                            <span className="event-color" style={{backgroundColor: "rgb(106, 181, 86)"}}></span>
                                            <div className="event-title-button">
                                                <p>Dinner date</p>
                                                <h5>06:00 PM to 08:00 PM</h5>
                                            </div>
                                        </li>
                                    </ol>
                                    <button className="btn btn-small composer-btn">Add an event</button>
                                </div>
                            </div>
                            <div className="outliner-widget">
                                <div className="outliner-widget-title">
                                    <svg className="widget-icon" width="30px" height="30px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M11 19.5H21" stroke="#fff" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path>
                                        <path d="M11 12.5H21" stroke="#fff" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path><path d="M11 5.5H21" stroke="#fff" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path>
                                        <path d="M3 5.5L4 6.5L7 3.5" stroke="#fff" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path><path d="M3 12.5L4 13.5L7 10.5" stroke="#fff" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path>
                                        <path d="M3 19.5L4 20.5L7 17.5" stroke="#fff" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path>
                                    </svg>
                                    <h2>Tasks</h2>
                                </div>
                                <div className="tasks-list-container">
                                    <ol className="tasks-list-content">
                                        <li className="task">
                                            <span className="task-color" style={{backgroundColor: "rgb(132, 83, 181)"}}></span>
                                            <input className="task-checkbox" type="checkbox" name="status" />
                                            <div className="task-title-container">
                                                <button className="task-title-button">
                                                    <span className="task-title">Clean rugs</span>
                                                </button>
                                            </div>
                                        </li>
                                        <li className="task">
                                            <span className="task-color" style={{backgroundColor: "rgb(106, 181, 86)"}}></span>
                                            <input className="task-checkbox" type="checkbox" name="status" />
                                            <div className="task-title-container">
                                                <button className="task-title-button">
                                                    <span className="task-title">Prep lunch for tomorrow</span>
                                                </button>
                                            </div>
                                        </li>
                                    </ol>
                                    <button className="btn btn-small composer-btn">Add a task</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="features-section">
                        <div className="features-section-image-left">
                            <img src={calendar} />
                        </div>
                        <div className="features-section-title-right">
                            <h1>Keep your calendar squeaky clean.</h1>
                            <p>Plainner makes your calendar super easy to use by displaying your events and tasks in a plain and simple format.</p>
                        </div>
                    </div>
                    <div className="features-section">
                        <div className="features-section-title">
                            <h1>Share with your friends and family.</h1>
                            <p>Keep up to date with everyone's schedules in real time with Plainner's syncing capabilities.</p>
                        </div>
                        <div className="features-section-image">
                            <img src={share} />
                        </div>
                    </div>
                </div>
            </div>
            <div className="bottom-container">
                <div className="bottom-curved-top">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
                        <path fill="#131313" fill-opacity="1" d="M0,288L60,293.3C120,299,240,309,360,288C480,267,600,213,720,208C840,203,960,245,1080,240C1200,235,1320,181,1380,154.7L1440,128L1440,0L1380,0C1320,0,1200,0,1080,0C960,0,840,0,720,0C600,0,480,0,360,0C240,0,120,0,60,0L0,0Z"></path>
                    </svg>
                </div>
                <div className="bottom-content">
                    <h1>Plainner is currently being developed</h1>
                    <h3>...but you don't have to wait to get started.</h3>
                    <h6>You can start using Plainner today by creating an account.  You'll get instant access to the newest content that we push out.  In the meantime, enjoy Plainner as it is as we continue development.</h6>
                </div>
                
            </div>
        </div>
    );
}

export default Home;
