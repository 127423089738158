import { useState, useEffect, useRef } from 'react';
import { API } from 'aws-amplify';
import * as mutations from '../../graphql/mutations';
import { toast } from 'react-hot-toast';

import DatePicker from 'react-datepicker'
import Modal from '../util/Modal';
import Popout from '../util/Popout';
import PopoutCalendar from '../calendar/PopoutCalendar';
import ColorGridItem from './ColorGridItem';
import { CategoryColors } from '../../util/CategoryColors';

const TaskElement = ({ id, date, status, color, title, description, dueDate }) => {
    const [editedTask, setEditedTask] = useState({
        id: id,
        status: status,
        color: color,
        title: title,
        description: description,
        dueDate: dueDate
    });
    // refs to use inside useEffect
    const propId = useRef(id);
    const propStatus = useRef(status);
    const propColor = useRef(color);
    // UI states
    const [modalOpen, showModal] = useState(false);
    const [editorOpen, showEditor] = useState(false);
    const [colorPopoutOpen, showColorPopout] = useState(false);
    const [duePopoutOpen, showDuePopout] = useState(false);
    
    // Update editedTask when props change
    useEffect(() => {
        setEditedTask(previousState => ({
            status: status,
            color: color,
            title: title,
            description: description,
            dueDate: dueDate,
        }));
        propStatus.current = status;
        propColor.current = color;
    }, [status, color, title, description, dueDate]);

    // Update task immediately after color or status change
    useEffect(() => {
        const updateTask = async () => {
            try {
                const updated = {
                    id: propId.current,
                    status: editedTask.status,
                    color: editedTask.color
                }
                await API.graphql({ query: mutations.updateTask, variables: { input: updated }});
            }
            catch (err) {
                toast.error('Error saving task');
            }
        }
        if (propStatus.current !== editedTask.status || propColor.current !== editedTask.color) updateTask();
    }, [editedTask.status, editedTask.color]);

    // Edit task property
    const editTask = (event) => {
        setEditedTask({
            ...editedTask,
            [event.target.name]: event.target.value
        });
    }

    // Edit task propery (for checkboxes)
    const onCheckboxChange = (event) => {
        setEditedTask({
            ...editedTask,
            [event.target.name]: event.target.checked
        });
    }

    // Handle due checkbox change
    const onDueCheckboxChange = (event) => {
        let newDate;
        if (event.target.checked) {
            newDate = new Date(date);
            newDate.setHours(23, 59);
            newDate = newDate.toISOString();
        }
        else newDate = null;
        setEditedTask({
            ...editedTask,
            dueDate: newDate
        });
    }

    // Edit due date when we press a date in PopoutCalendar or type it in the input box (aslo edits time)
    const onDueDateSelected = (date) => {
        let newDate = date;
        if (date !== null) newDate = date.toISOString();
        setEditedTask({
            ...editedTask,
            dueDate: newDate
        });
    }

    // Returns the due input text for input box
    const getDueDateText = () => {
        if (editedTask.dueDate !== null) return new Date(editedTask.dueDate);
    }

    const getDueDateButtonText = () => {
        if (editedTask.dueDate !== null) {
            const dueDate = new Date(editedTask.dueDate);
            const current = new Date();
            if (dueDate.toDateString() === current.toDateString()) {
                return `Due at ${dueDate.toLocaleTimeString('en-US', {hour: '2-digit', minute:'2-digit'})}`;
            }
            else return `Due on ${dueDate.toLocaleDateString('en-US')}`;
        }
        else return 'Set due date'
    }

    const closeModal = () => {
        showModal(false);
        showColorPopout(false);
        showDuePopout(false);
    }

    // AWS: update task
    const saveTask = async () => {
        if (editedTask.title.trim().length === 0) {
            toast.error('Title cannot be empty!');
            return;
        }
        try {
            const updated = {
                id: id,
                status: editedTask.status,
                color: editedTask.color,
                title: editedTask.title,
                description: editedTask.description,
                dueDate: editedTask.dueDate,
            }
            await API.graphql({ query: mutations.updateTask, variables: { input: updated } });
        }
        catch (err) {
            toast.error('Error saving task');
            console.log(err);
        }
    }

    // AWS: delete task
    const deleteTask = async () => {
        try {
            const deleted = {
                id: id
            }
            await API.graphql({ query: mutations.deleteTask, variables: { input: deleted }});
        }
        catch (err) {
            toast.error('Error deleting task');
        }
    }

    return (
        <li className="task">
            <span className="task-color" style={{backgroundColor: editedTask.color}}></span>
            <input className="task-checkbox" type="checkbox" name="status" onChange={onCheckboxChange} checked={!!editedTask.status}></input>
            <div className="task-title-container">
                
                <button className="task-title-button" onClick={() => showModal(true)}>
                    <span className="task-title">
                        { editedTask.title }
                    </span>
                </button>
            </div>
            <Modal shown={modalOpen} close={closeModal}>
                <div className="task-modal-title-container">
                    <input className="task-checkbox big-checkbox" type="checkbox" name="status" onChange={onCheckboxChange} checked={!!editedTask.status}></input>
                    <textarea className="task-modal-title" name="title" onChange={editTask} onBlur={saveTask} value={editedTask.title} placeholder="Add a title..."></textarea>
                </div>
                <div className="task-modal-options-container">
                    <div className="task-modal-option">
                        <button id="date-button" className="btn btn-small btn-task-modal" onClick={() => showDuePopout(!duePopoutOpen)}>
                            <svg height="24 " width="24" className="btn-svg" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <g id="SVGRepo_bgCarrier"></g>
                                <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g>
                                <g id="SVGRepo_iconCarrier"> 
                                    <path d="M12 9.5V13.5L14.5 15M12 5C7.30558 5 3.5 8.80558 3.5 13.5C3.5 18.1944 7.30558 22 12 22C16.6944 22 20.5 18.1944 20.5 13.5C20.5 8.80558 16.6944 5 12 5ZM12 5V2M10 2H14M20.329 5.59204L18.829 			4.09204L19.579 4.84204M3.67102 5.59204L5.17102 4.09204L4.42102 4.84204" stroke="#fff" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path> 
                                </g>
                            </svg>
                            {getDueDateButtonText()}
                        </button>
                        <Popout title="Due date" buttonId="date-button" shown={duePopoutOpen} close={() => showDuePopout(false)}>
                            <div className="due-date-info-container">
                                <PopoutCalendar date={date} dueDate={editedTask.dueDate !== null ? editedTask.dueDate : date} setNewDate={onDueDateSelected}/>
                                <p style={{margin: 0}}>Enable due date</p>
                                <div className="due-date-checkbox-container">
                                    <input className="task-checkbox" type="checkbox" name="usingDueDate" onChange={onDueCheckboxChange} checked={editedTask.dueDate !== null}></input>
                                    <DatePicker className="date-input" onChange={onDueDateSelected} selected={getDueDateText()} open={false} placeholderText="M/D/YYYY"/>
                                    <DatePicker className="time-input" onChange={onDueDateSelected} selected={getDueDateText()} open={false} showTimeSelect showTimeSelectOnly dateFormat="h:mm aa" placeholderText="H:MM A"/>
                                </div>
                                <button className="btn btn-small" onClick={() => { saveTask(); showDuePopout(false) }}>Save</button>
                            </div>
                        </Popout>
                    </div>
                    <div className="task-modal-option">
                        <button id="color-button" className="btn btn-small btn-task-modal" onClick={() => showColorPopout(!colorPopoutOpen)} style={{backgroundColor: editedTask.color}}>
                            <svg height="24" width="24" className="btn-svg" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <g id="SVGRepo_bgCarrier" strokeWidth="0"></g><g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g>
                                <g id="SVGRepo_iconCarrier"> 
                                    <path d="M8.4 3H4.6C4.03995 3 3.75992 3 3.54601 3.10899C3.35785 3.20487 3.20487 3.35785 3.10899 3.54601C3 3.75992 3 4.03995 3 4.6V8.4C3 8.96005 3 9.24008 3.10899 9.45399C3.20487 9.64215 3.35785 			9.79513 3.54601 9.89101C3.75992 10 4.03995 10 4.6 10H8.4C8.96005 10 9.24008 10 9.45399 9.89101C9.64215 9.79513 9.79513 9.64215 9.89101 9.45399C10 9.24008 10 8.96005 10 8.4V4.6C10 4.03995 10 3.75992 		9.89101 3.54601C9.79513 3.35785 9.64215 3.20487 9.45399 3.10899C9.24008 3 8.96005 3 8.4 3Z" stroke="#fff" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path> 
                                    <path d="M19.4 3H15.6C15.0399 3 14.7599 3 14.546 3.10899C14.3578 3.20487 14.2049 3.35785 14.109 3.54601C14 3.75992 14 4.03995 14 4.6V8.4C14 8.96005 14 9.24008 14.109 9.45399C14.2049 9.64215 14.3578 		9.79513 14.546 9.89101C14.7599 10 15.0399 10 15.6 10H19.4C19.9601 10 20.2401 10 20.454 9.89101C20.6422 9.79513 20.7951 9.64215 20.891 9.45399C21 9.24008 21 8.96005 21 8.4V4.6C21 4.03995 21 3.75992 			20.891 3.54601C20.7951 3.35785 20.6422 3.20487 20.454 3.10899C20.2401 3 19.9601 3 19.4 3Z" stroke="#fff" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path> 
                                    <path d="M19.4 14H15.6C15.0399 14 14.7599 14 14.546 14.109C14.3578 14.2049 14.2049 14.3578 14.109 14.546C14 14.7599 14 15.0399 14 15.6V19.4C14 19.9601 14 20.2401 14.109 20.454C14.2049 20.6422 14.3578 		20.7951 14.546 20.891C14.7599 21 15.0399 21 15.6 21H19.4C19.9601 21 20.2401 21 20.454 20.891C20.6422 20.7951 20.7951 20.6422 20.891 20.454C21 20.2401 21 19.9601 21 19.4V15.6C21 15.0399 21 14.7599 			20.891 14.546C20.7951 14.3578 20.6422 14.2049 20.454 14.109C20.2401 14 19.9601 14 19.4 14Z" stroke="#fff" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path> 
                                    <path d="M8.4 14H4.6C4.03995 14 3.75992 14 3.54601 14.109C3.35785 14.2049 3.20487 14.3578 3.10899 14.546C3 14.7599 3 15.0399 3 15.6V19.4C3 19.9601 3 20.2401 3.10899 20.454C3.20487 20.6422 3.35785 			20.7951 3.54601 20.891C3.75992 21 4.03995 21 4.6 21H8.4C8.96005 21 9.24008 21 9.45399 20.891C9.64215 20.7951 9.79513 20.6422 9.89101 20.454C10 20.2401 10 19.9601 10 19.4V15.6C10 15.0399 10 14.7599 			9.89101 14.546C9.79513 14.3578 9.64215 14.2049 9.45399 14.109C9.24008 14 8.96005 14 8.4 14Z" stroke="#fff" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path> 
                                </g>
                            </svg>
                            Color
                        </button>
                        <Popout title="Color" buttonId="color-button" shown={colorPopoutOpen} close={() => showColorPopout(false)}>
                            <div className="task-color-grid-container">
                                <ColorGridItem color={CategoryColors.RED} clickEvent={editTask} />
                                <ColorGridItem color={CategoryColors.ORANGE} clickEvent={editTask} />
                                <ColorGridItem color={CategoryColors.YELLOW} clickEvent={editTask} />
                                <ColorGridItem color={CategoryColors.GREEN} clickEvent={editTask} />
                                <ColorGridItem color={CategoryColors.BLUE} clickEvent={editTask} />
                                <ColorGridItem color={CategoryColors.PURPLE} clickEvent={editTask} />
                            </div>
                        </Popout>
                    </div>
                </div>
                <p className="description-header">Description</p>
                <textarea className="task-modal-description" name="description" onChange={editTask} value={editedTask.description} placeholder="Add a description..." onFocus={() => showEditor(true)}></textarea>
                {
                    editorOpen && (
                        <div>
                            <button className="btn btn-small" onClick={() => {
                                saveTask()
                                showEditor(false)
                            }}>Save</button>
                            <button className="btn btn-small btn-grey" onClick={() => showEditor(false)}>Cancel</button>
                        </div>
                    )
                }
                <div className="modal-controls-container">
                    <button className="btn btn-small btn-delete modal-delete" onClick={deleteTask}>Delete task</button>
                </div>
            </Modal>
        </li>
    );
}

export default TaskElement;