import React from 'react';
import { Auth } from 'aws-amplify';

class ProfileButton extends React.Component {
    signOut = async () => {
        try {
            await Auth.signOut();
            window.location.reload(false);
        }
        catch (err) {
            console.log('error signing out, ', err);
        }
    }

    render() {
        return (
            <div>
                <button className="btn" onClick={this.signOut}>
                    Sign out
                </button>
            </div>
        );
    }
}
export default ProfileButton;