import { Auth } from 'aws-amplify'
import React from 'react';

class ProtectedComponent extends React.Component {
    constructor () {
        super()
        this.state = { isLoggedIn: undefined };
    }

    componentDidMount() {
        this.checkIfLoggedIn();
    }

    checkIfLoggedIn = async () => {
        try {
            await Auth.currentAuthenticatedUser();
            this.setState( { isLoggedIn: true } );
        }
        catch {
            this.setState( { isLoggedIn: false } );
        }
    }

    render() {
        return  (
            <div>
                {
                    this.state.isLoggedIn === undefined && (
                        <></>
                    )
                }
                {
                    this.state.isLoggedIn === true && (
                        <div>
                            {this.props.children}
                        </div>
                    )
                }
            </div>
            
        )
    }
}

export default ProtectedComponent;