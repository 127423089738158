import { useState } from 'react'; 
import { useNavigate } from 'react-router-dom';
import { API } from 'aws-amplify';
import * as mutations from '../../graphql/mutations';

const DashboardCard = ({ id, title, color }) => {
    const [editedTitle, setEditedTitle] = useState(title);
    const navigate = useNavigate();

    const openPlanner = () => {
        navigate(`/p/${id}`);    
    }

    const onTitleChange = (e) => {
        setEditedTitle(e.target.value);
    }

    const editTitle = async () => {
        if (editedTitle.trim().length === 0) {
            setEditedTitle(title);
            return;
        }
        if (editedTitle === title) return;
        try {
            const updated = {
                id: id,
                title: editedTitle
            }
            await API.graphql({ query: mutations.updatePlanner, variables: { input: updated }});
        }
        catch (err) {
            console.log(err);
        }
    }

    return (
        <div className="dashboard-card">
            <div className="dashboard-card-background-container" style={{backgroundColor: color}}>
            </div>
            <div className="dashboard-card-title-container">
                <div className="dashboard-card-title">
                    <input className="name-item-input" name="title" value={editedTitle} onChange={onTitleChange} onBlur={editTitle} placeholder="Add a title..."></input>
                    <button className="btn btn-wide" onClick={openPlanner}>Open</button>
                </div>
            </div>
        </div>
    );
}

export default DashboardCard;