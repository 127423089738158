import ReactDOM from "react-dom";

const Modal = ({ children, shown, close, className }) => {
    return shown ? ReactDOM.createPortal(
        <div className="modal-backdrop" onClick={ close }>
            <div className={"modal-content " + className} onClick={e => { e.stopPropagation(); }}>
                <button className="modal-close" onClick={close}></button>
                { children }
            </div>
        </div>,
        document.getElementById("planner-portal-container")
    ) : null;
}

export default Modal;