import { useState, useEffect } from 'react';
import DatePicker from 'react-datepicker';

const BlurryDatePicker = ({
    className,
    name,
    selected,
    onFocus,
    onBlur,
    open,
    placeholderText,
    showTimeSelect,
    showTimeSelectOnly,
    dateFormat,
    disabled
}) => {
    const [date, setNewDate] = useState(selected);

    useEffect(() => {
        setNewDate(selected);
    }, [selected]);

    const test = () => {
        onBlur(date);
    }

    return (
        <span onBlur={test}>
            <DatePicker 
                className={className}
                name={name}
                selected={selected}
                onChange={setNewDate}
                onFocus={onFocus}
                open={open}
                placeholderText={placeholderText}
                showTimeSelect={showTimeSelect}
                showTimeSelectOnly={showTimeSelectOnly}
                dateFormat={dateFormat}
                disabled={disabled}
            />
        </span>
    )
}

export default BlurryDatePicker;