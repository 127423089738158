import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import AppNavbar from '../components/AppNavbar';
import { Outlet } from 'react-router-dom';

function WithAppNav() {
    const { id: newId = null } = useParams();
    const [id, setId] = useState(newId);

    useEffect(() => {
        setId(newId);
    }, [newId])

    return (
        <div className="wrapper">
            <AppNavbar plannerId={id} />
            <Outlet />
        </div>
    )
}

export default WithAppNav;