import { useState, useEffect } from 'react';
import DashboardEventElement from './DashboardEventElement';

const DashboardEventsWidget = ({ planners, events }) => {
	const [filteredEvents, setFilteredEvents] = useState([]);

	useEffect(() => {
		const date = new Date();
		if (planners.length !== 0 && events.length !== 0) setFilteredEvents(events
	        .filter(event => new Date(event.date).toLocaleDateString('en-US') === date.toLocaleDateString('en-US'))
	        .map(event => (
	            <DashboardEventElement
	                id={event.id}
	                plannerId={event.plannerEventsId}
	                plannerTitle={planners.filter(planner => planner.id === event.plannerEventsId)[0].title}
	                date={event.date}
	                endDate={event.endDate}
	                isAllDay={event.isAllDay}
	                color={event.color}
	                title={event.title}
	                description={event.description}
	                key={event.id}
	            />
    		)
	    ));
	}, [planners, events]);

	return (
		<div>
			{
				filteredEvents.length > 0 ? (
					filteredEvents
				) : (
					<div className="no-elements-banner-container">
						<h4 className="no-elements-header">No events for today</h4>
						<p className="no-elements-text">Kick back and relax.</p>
					</div>
				)
			}
		</div>
	)
}

export default DashboardEventsWidget;