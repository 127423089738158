export default function AuthToaster({ message }) {
    return (
        <div className="auth-toaster">
            {
                !message ? <></>
                :
                <div className="auth-toast">
                    <p>{ message }</p>
                </div>
            }
        </div>
    )
}