import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Toaster } from 'react-hot-toast';
import { API, graphqlOperation } from 'aws-amplify';
import * as queries from '../graphql/queries';
import * as subscriptions from '../graphql/subscriptions';

import AppPlannerNavbar from '../components/AppPlannerNavbar';
import Outliner from '../components/Outliner';
import TaskList from '../components/outliner/TaskList';
import EventList from '../components/outliner/EventList';
import ContentWrapper from '../components/ContentWrapper';
import DesktopCalendar from '../components/calendar/DesktopCalendar';

function PlainnerMain() {
    const { id } = useParams();

    const [outlinerOpen, setOutlinerOpen] = useState(true);
    const [error, setError] = useState(null);
    
    const [date, setDate] = useState(new Date());

    const [tasks, setTasks] = useState([]);
    const [events, setEvents] = useState([]);

    const monthNames = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

    useEffect(() => {
        const taskCreateSub = API.graphql(graphqlOperation(subscriptions.onCreateTask)).subscribe({
            next: ({ value }) => {
                setTasks(tasks => {
                    if (tasks.length !== 0) {
                        if (tasks[tasks.length - 1].key === 'temp' && tasks[tasks.length - 1].title === value.data.onCreateTask.title) {
                            tasks.pop();
                        }
                    }
                    return [...tasks, value.data.onCreateTask];
                });
            }
        });

        const taskUpdateSub = API.graphql(graphqlOperation(subscriptions.onUpdateTask)).subscribe({
            next : ({ value }) => {
                setTasks(tasks => {
                    const toUpdateIndex = tasks.findIndex(item => item.id === value.data.onUpdateTask.id);
                    return [...tasks.slice(0, toUpdateIndex), value.data.onUpdateTask, ...tasks.slice(toUpdateIndex + 1)]
                });
            }
        });

        const taskDeleteSub = API.graphql(graphqlOperation(subscriptions.onDeleteTask)).subscribe({
            next : ({ value }) => {
                setTasks(tasks => {
                    const toDeleteIndex = tasks.findIndex(item => item.id === value.data.onDeleteTask.id);
                    return [...tasks.slice(0, toDeleteIndex), ...tasks.slice(toDeleteIndex + 1)];
                });
            }
        });

        return () => {
            taskCreateSub.unsubscribe();
            taskUpdateSub.unsubscribe();
            taskDeleteSub.unsubscribe();
            
        }
    }, []);

    useEffect(() => {
        const eventCreateSub = API.graphql(graphqlOperation(subscriptions.onCreateEvent)).subscribe({
            next: ({ value }) => {
                setEvents(events => {
                    if (events.length !== 0) {
                        if (events[events.length - 1].key === 'temp' && events[events.length - 1].title === value.data.onCreateEvent.title) {
                            events.pop();
                        }
                    }
                    return [...events, value.data.onCreateEvent];
                });
            }
        });

        const eventUpdateSub = API.graphql(graphqlOperation(subscriptions.onUpdateEvent)).subscribe({
            next : ({ value }) => {
                setEvents(events => {
                    const toUpdateIndex = events.findIndex(item => item.id === value.data.onUpdateEvent.id);
                    return [...events.slice(0, toUpdateIndex), value.data.onUpdateEvent, ...events.slice(toUpdateIndex + 1)]
                });
            }
        });

        const eventDeleteSub = API.graphql(graphqlOperation(subscriptions.onDeleteEvent)).subscribe({
            next : ({ value }) => {
                setEvents(events => {
                    const toDeleteIndex = events.findIndex(item => item.id === value.data.onDeleteEvent.id);
                    return [...events.slice(0, toDeleteIndex), ...events.slice(toDeleteIndex + 1)];
                });
            }
        });

        return () => {
            eventCreateSub.unsubscribe();
            eventUpdateSub.unsubscribe();
            eventDeleteSub.unsubscribe();
        }
    }, [])

    useEffect(() => {
        setTasks([]);
        setEvents([]);
        // 'fetch data' bruh what data are we fetching
        const fetchData = async () => {
            try {
                const response = await API.graphql({
                    query: queries.getPlanner,
                    variables: { id: id }
                });
                if (response.data.getPlanner !== null) {
                    setError(false);
                    setTasks(response.data.getPlanner.tasks.items);
                    setEvents(response.data.getPlanner.events.items);
                }
                else setError(true);
            }
            catch (err) {
                setError(true);
            }
        }

        fetchData();
    }, [id]);

    const toggleOutliner = () => {
        setOutlinerOpen(!outlinerOpen);
    }

    const setNewDate = (date) => {
        setDate(date);
    }

    return (
        <>
            <div className="wrapper">
                <AppPlannerNavbar plannerId={id} />
                {
                    error === null && (
                        <main className="app-container">

                        </main>
                    )
                }
                {
                    error === false && (
                        <main className="app-container">
                            <Outliner shown={outlinerOpen} toggleOutliner={toggleOutliner}>
                                <h1>
                                    {
                                        monthNames[date.getMonth()] + ' ' + date.getDate()
                                    }
                                </h1>
                                <EventList date={date} plannerId={id} events={events}/>
                                <TaskList date={date} plannerId={id} tasks={tasks}/>
                                <Toaster position="bottom-left"/>
                            </Outliner>
                            <ContentWrapper fullscreen={!outlinerOpen}>
                                <DesktopCalendar date={date} events={events} setNewDate={setNewDate}/>
                            </ContentWrapper>
                        </main>
                    )
                }
                {
                    error === true && (
                        <main className="app-container">
                            <div style={{width:"100%", textAlign:"center"}}>
                                <div style={{padding:"20px"}}>
                                    <h1>Planner not found</h1>
                                    <p>This planner may be private or it may not exist.</p>
                                </div>
                                
                            </div>
                        </main>
                    )
                }
                
            </div>
            <div id="planner-portal-container">
                
            </div>
        </>
    );
}

export default PlainnerMain;