import Router from './util/Router';
import awsconfig from './aws-exports';
import { Amplify } from 'aws-amplify';
Amplify.configure(awsconfig);

function App() {
  return (
    <>
      <div className="content">
        <Router />
      </div>
    </>
  );
}

export default App;
