import { useNavigate } from 'react-router-dom';


const DashboardEventElement = ({ id, plannerId, plannerTitle, date, endDate, isAllDay, color, title, description }) => {
	const navigate = useNavigate();

	// Returns the date for this event as a formatted date (shown in the event list)
    const getFormattedDateText = () => {
        if (!isAllDay) {
            const startDateObj = new Date(date);
            const endDateObj = new Date(endDate);
            const current = new Date();
            if (startDateObj.toDateString() === current.toDateString()) {
                if (endDateObj.toDateString() === current.toDateString()) {
                    return `${startDateObj.toLocaleTimeString('en-US', {hour: '2-digit', minute: '2-digit'})} to ${endDateObj.toLocaleTimeString('en-US', {hour: '2-digit', minute: '2-digit'})}`;
                }
                else {
                    return `${startDateObj.toLocaleTimeString('en-US', {hour: '2-digit', minute: '2-digit'})} to ${endDateObj.toLocaleDateString('en-US')}`;
                }
            }
            else {
                if (endDateObj.toDateString() === current.toDateString()) {
                    return `${startDateObj.toLocaleDateString('en-US')} to today at ${endDateObj.toLocaleTimeString('en-US', {hour: '2-digit', minute: '2-digit'})}`;
                }
                else {
                    return `${startDateObj.toLocaleDateString('en-US')} to ${endDateObj.toLocaleDateString('en-US')}`;
                }
            }
        }
        else {
            const startDateObj = new Date(date);
            const endDateObj = new Date(endDate);
            const current = new Date();
            if (startDateObj.toDateString() === current.toDateString()) {
                if (endDateObj.toDateString() === current.toDateString()) {
                    return `All day today`;
                }
                else {
                    return `Today to ${endDateObj.toLocaleDateString('en-US')}`;
                }
            }
            else {
                if (endDateObj.toDateString() === current.toDateString()) {
                    return `${startDateObj.toLocaleDateString('en-US')} to today`;
                }
                else {
                    return `${startDateObj.toLocaleDateString('en-US')} to ${endDateObj.toLocaleDateString('en-US')}`;
                }
            }
        }
    }

	const openPlanner = () => {
		navigate(`/p/${plannerId}`);
	}

	return (
		<li className="event">
            <span className="event-color" style={{ backgroundColor: color }}></span>
            <div className="event-title-button" onClick={openPlanner}>
                <p>{ title }</p>
                <h5>{ getFormattedDateText() }</h5>
            </div>
            <p style={{ marginLeft:'auto' }}>{ plannerTitle }</p>
        </li>
	);
}

export default DashboardEventElement;