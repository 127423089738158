import { useEffect } from "react";
import { useState } from "react";

const ProfileImage = ({ name }) => {
    const [nameLetter, setNameLetter] = useState('');

    useEffect(() => {
        if (name === "") return;
        const nameArray = name.split(" ");
        const firstName = nameArray[0][0];
        const lastName = nameArray[nameArray.length - 1][0];
        setNameLetter(firstName + lastName);
    }, [name]);

    return (
        <>
            {
                name !== '' && (
                    <div className="profile-image">
                        <p>{ nameLetter }</p>
                    </div>
                )
            }
        </>
    );
}

export default ProfileImage;