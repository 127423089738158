import { useState, useEffect } from 'react';
import PopoutCalendarDay from './PopoutCalendarDay';

function PopoutCalendar({ date, dueDate, setNewDate, secondaryDate }) {
    const [calendar, setCalendar] = useState([]);
    const monthNames = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

    useEffect(() => {
        if (date === null) return;
        // Create a list of every date object for this month
        const createMonth = () => {
            const dateObj = new Date(date);
            const dueDateObj = dueDate !== null ? new Date(dueDate) : null;
            const sDateObj = secondaryDate !== null ? new Date(secondaryDate) : null;
            const firstDay = new Date(dateObj.getFullYear(), dateObj.getMonth(), 1).getDay();
            const daysCount = new Date(dateObj.getFullYear(), dateObj.getMonth() + 1, 0).getDate();
            const prevMonthCount = new Date(dateObj.getFullYear(), dateObj.getMonth(), 0).getDate();
            let key = 0;
            let daysInCalendar = [];

            for (let i = prevMonthCount - firstDay + 1; i <= prevMonthCount; i++) {
                let newDate;
                if (dateObj.getMonth() === 0) {
                    newDate = new Date(dateObj.getFullYear() - 1, 11, i);
                }
                else {
                    newDate = new Date(dateObj.getFullYear(), dateObj.getMonth() - 1, i);
                }
                let newDay;
                if (sDateObj !== null) {
                    if (newDate.toDateString() === sDateObj.toDateString()) newDay = createDay(newDate, key, 'pop-calendar-day-active');
                    else if ((newDate < sDateObj && newDate > dueDateObj) || (newDate > sDateObj && newDate < dueDateObj)) newDay = createDay(newDate, key, 'pop-calendar-day-between');
                    else newDay = createDay(newDate, key, 'inactive-month');
                }
                else newDay = createDay(newDate, key, 'inactive-month');
                daysInCalendar.push(newDay);
                key++;
            }

            for (let i = 1; i <= daysCount; i++) {
                let newDate = new Date(dateObj.getFullYear(), dateObj.getMonth(), i);
                let newDay;
                // check if primary date is equal to date being added to calendar
                if (newDate.toDateString() === dueDateObj.toDateString()) newDay = createDay(newDate, key, 'pop-calendar-day-active');
                // check if secondary date exists, if so, check if it is equal to date
                else if (sDateObj !== null) {
                    if (newDate.toDateString() === sDateObj.toDateString()) newDay = createDay(newDate, key, 'pop-calendar-day-active');
                    else if ((newDate < sDateObj && newDate > dueDateObj) || (newDate > sDateObj && newDate < dueDateObj)) newDay = createDay(newDate, key, 'pop-calendar-day-between');
                    else newDay = createDay(newDate, key, '');
                }
                // else skip
                else newDay = createDay(newDate, key, '');
                daysInCalendar.push(newDay);
                key++;
            }
            
            const daysLeft = 42 - daysInCalendar.length;
            for (let i = 1; i <= daysLeft; i++) {
                let newDate;
                if (dateObj.getMonth() === 11) {
                    newDate = new Date(dateObj.getFullYear() + 1, 0, i);
                }
                else {
                    newDate = new Date(dateObj.getFullYear(), dateObj.getMonth() + 1, i);
                }
                let newDay;
                if (sDateObj !== null) {
                    if (newDate.toDateString() === sDateObj.toDateString()) newDay = createDay(newDate, key, 'pop-calendar-day-active');
                    else if ((newDate < sDateObj && newDate > dueDateObj) || (newDate > sDateObj && newDate < dueDateObj)) newDay = createDay(newDate, key, 'pop-calendar-day-between');
                    else newDay = createDay(newDate, key, 'inactive-month');
                }
                else newDay = createDay(newDate, key, 'inactive-month');
                daysInCalendar.push(newDay);
                key++;
            }

            return daysInCalendar;
        }
        
        setCalendar(createMonth());
    }, [dueDate, date, secondaryDate]);

    const createDay = (date, key, newClass) => {
        let calendarDay = {
            date: date,
            key: key,
            newClass: newClass
        }

        return calendarDay;
    }

    return (
        <div className="calendar">
            <div className="calendar-header" style={{border:"none"}}>
                <p>
                    {
                        monthNames[new Date(dueDate).getMonth()] + " " + new Date(dueDate).getFullYear()
                    }
                </p>
            </div>
            <ol className="popout-calendar">
                <PopoutCalendarDayTitle day="SUN"/>
                <PopoutCalendarDayTitle day="MON"/>
                <PopoutCalendarDayTitle day="TUE"/>
                <PopoutCalendarDayTitle day="WED"/>
                <PopoutCalendarDayTitle day="THU"/>
                <PopoutCalendarDayTitle day="FRI"/>
                <PopoutCalendarDayTitle day="SAT"/>
                {
                    calendar.map((calendarDay) => {
                        return (
                            <PopoutCalendarDay 
                                date={calendarDay.date} 
                                newClass={calendarDay.newClass}
                                setNewDate={setNewDate}
                                key={calendarDay.key}
                            />
                        )
                    })
                }
            </ol>
        </div>
        
    );
}

const PopoutCalendarDayTitle = ({day}) => {
    return (
        <p style={{fontSize: 12, fontWeight: 500, textAlign: 'center', width: 40, marginBottom: '10px'}}>{day}</p>
    )
}


export default PopoutCalendar;