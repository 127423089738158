const DashboardWidget = ({ children, title, icon }) => {
	return (
		<div className="dashboard-widget">
			<div className="dashboard-widget-title">
				{ icon }
				<h2>{ title }</h2>
			</div>
			<div className="dashboard-widget-content">
				{ children }
			</div>
		</div>
	);
}

export default DashboardWidget;