import React, { useState, useRef, useEffect } from 'react';

export default ({ inputClassName, value, name, onChange, onBlur }) => {
  const [content, setContent] = useState(value);
  const [width, setWidth] = useState(0);
  const span = useRef();

  useEffect(() => {
    setWidth(span.current.offsetWidth - 10);
  }, [content]);

  const changeHandler = evt => {
    setContent(evt.target.value);
    onChange(evt);
  };

  return (
    <div>
      <span id="hide" ref={span} className={inputClassName} style={{ position:"absolute", visibility:"hidden", whiteSpace:"pre"}}>{content}</span>
      <input className={inputClassName} type="text" name={name} value={value} onBlur={onBlur} style={{ width }} onChange={changeHandler} />
    </div>
  );
};